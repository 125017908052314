import React from "react";
import HeroImage from "../../Component/Images/about-banner.png";
import styled, { keyframes } from "styled-components";
import {
  Button,
  Container,
} from "../../Component/styledComponents/styledComponents";
import BallImage from "../../Component/Images/ball.svg";
import MenImage from "../../Component/Images/men.svg";
import HandImage from "../../Component/Images/hand.svg";
import { useNavigate } from "react-router-dom";

const Root = styled.div`
  background-color: #fff;
  padding-top: 190px;
  min-height: 560px;
  @media (max-width: 1098px) {
    padding-bottom: 30px;
  }
  @media (max-width: 905px) {
    padding-top: 140px;
  }
  @media (max-width: 767px) {
    height: 100%;
    min-height: 100%;
    padding-top: 100px;
    padding-bottom: 30px;
  }
`;
const Image = styled.img`
  width: 100%;
  object-fit: contain;
  z-index: 4;
  @media (max-width: 1015px) {
  }
`;
const ImageWrapper = styled.div`
  position: relative;
  bottom: 0;
  display: flex;
  align-items: baseline;
  justify-content: flex-end;
  @media (max-width: 1030px) {
    top: 35px;
  }
  @media (max-width: 901px) {
    top: 90px;
  }
  @media (max-width: 860px) {
    top: 110px;
  }
  @media (max-width: 820px) {
    top: 140px;
  }
  @media (max-width: 767px) {
    display: none;
  }
`;

const Div = styled.div`
  width: 50%;
  padding-bottom:50px;
  @media (max-width: 767px) {
    width: 100%;
    min-width: 100%;
    padding-bottom:20px;
  }
`;

const ImageSection = styled.div`
  width: 50%;
  position: relative;
  display: flex;
  align-items: baseline;
  justify-content: flex-end;
  @media (max-width: 767px) {
    display: none;
  }
`;

const HeadingWrapper = styled.div`
  position: relative;
`;
const Heading = styled.div`
  color: #00a97c;
  font-size: 1.3rem;
  font-weight: 600;
`;
const BoldHeading = styled.div`
  font-size: 3.2rem;
  font-weight: 400;
  font-family: Geist;
  @media (max-width: 905px) {
    font-size: 2.5rem;
  }
  @media (max-width: 767px) {
    font-size: 2rem;
  }
`;
const LargeText = styled.div`
  font-size: 3.2rem;
  font-weight: 400;
  font-family: Geist;
  @media (max-width: 767px) {
    font-size: 2rem;
    line-height: 1.8rem;
  }
`;
const ParagraphWrapper = styled.div``;
const Para = styled.div`
  font-size: 1.18rem;
  font-family: "Geist";
  width: 100%;
  font-weight: 400;
  padding-top: 1rem;
  line-height: 25px;
  @media (max-width: 767px) {
    width: 100%;
  }
`;
const H1 = styled.h1`
  margin: 0;
  font-size: 1rem;
  font-family: Roboto;
  font-weight: 400;
  display: inline;
`;

const rotate = keyframes`
  0% {
    transform: rotate(-5deg);
  }
  100%{
    transform: rotate(10deg);
  }
`;
const animation = keyframes`
0%{
  transform: rotate(0deg);
  top:15%;
 }
 100%{
  transform: rotate(90deg) ;
  top:0%;
 }

`;
const BallAnimationImage = styled.img`
  position: absolute;
  top: 12%;
  left: 23%;
  animation-timing-function: ease-in;
  animation: ${animation} 1.5s forwards;
`;
const HandAnimationImage = styled.img`
  position: absolute;
  top: 19%;
  left: 22%;
  z-index: 1;
  transform-origin: bottom right;
  animation: ${rotate} 1.3s forwards;
  @media (max-width: 1105px) {
    width: 120px;
  }
  @media (max-width: 1007px) {
    width: 105px;
  }
  @media (max-width: 887px) {
    width: 100px;
  }
  @media (max-width: 844px) {
    width: 90px;
  }
`;

const Herosection = () => {
  const navigate = useNavigate();
  return (
    <Root>
      <Container>
        <Div>
          <HeadingWrapper>
            <Heading> About us </Heading>
            <BoldHeading>We deliver fruitful </BoldHeading>
            <LargeText>
              solutions which{" "}
              <span style={{ fontWeight: "700", fontSize: "3.2rem" }}>
                works!{" "}
              </span>{" "}
            </LargeText>
          </HeadingWrapper>
          <ParagraphWrapper>
            <Para>
              <H1></H1>
              Established in 2008, BastionEx boasts 15+ years of expertise in
              delivering exceptional web and mobile solutions.
            </Para>
            <Para>
              Our services encompass creative website/app design, custom web
              application programming, and cutting-edge technologies like AI,
              blockchain, and game development. We empower businesses with
              innovative solutions that drive growth and success. At BastionEx,
              we prioritize creativity, proactiveness, and a deep understanding
              of client needs to ensure they stay ahead of the curve.
            </Para>
          </ParagraphWrapper>
          <Button
          style={{fontFamily:"Geist", fontSize:"16px"}}
            bgcolor="#000"
            mtop="20px"
            color="#fff"
            top="15px"
            bottom="15px"
            left="30px"
            right="30px"
            radius="30px"
            onClick={() => {
              navigate("/contact_us");
            }}
          >
            CONTACT US
          </Button>
        </Div>
        <ImageSection>
          <ImageWrapper>
            <BallAnimationImage src={BallImage} />
            <HandAnimationImage src={HandImage} />
            <Image src={MenImage} alt="About Bastionex Infotech" />
          </ImageWrapper>
        </ImageSection>
      </Container>
    </Root>
  );
};

export default Herosection;
