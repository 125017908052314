import React, { useState } from "react";
import HeroImage from "../../Component/Images/hero-banner.gif";
import styled, { keyframes } from "styled-components";

import {
  Button,
  Container,
} from "../../Component/styledComponents/styledComponents";
import YellowIcon from "../../Component/Images/yellow.png";
import GreenDot from "../../Component/Images/green-dot.png";
import PinkEtherIcon from "../../Component/Images/pink.png";
import PinkDotIcon from "../../Component/Images/pink-dot.png";
import VoiletBigIcon from "../../Component/Images/voilet-big.png";
import GreendotIcon from "../../Component/Images/green-dot.png";
import ChainIcon from "../../Component/Images/green.png";
import { useNavigate } from "react-router-dom";
import axios from "axios";
import SucessPopup from "../../Component/SucessPopup";
import EmailAlreadyExist from "../../Component/EmailAlreadyExist";
import { useFormik } from "formik";
import * as Yup from "yup";
import Swal from "sweetalert2";
import TextField from "@mui/material/TextField";
import CircularLoader from "../../Component/CircularLoader";
import { environmentVariables } from "../../config/env.config";
import PhoneInput from "react-phone-input-2";
import "react-phone-input-2/lib/style.css";
import { isValidPhoneNumber } from "../../Component/Common/PhoneValidate";


const baseUrl = environmentVariables?.apiUrl;
const Root = styled.div`

  background-color: #f1f7f5;
  padding-top: 190px;
  padding-bottom: 30px;
  position: relative;
  @media (max-width: 1200px) {
    padding-bottom: 30px;
  }
  @media (max-width: 994px) {
    padding-top: 140px;
    padding-bottom: 100px;
  }
  @media (max-width: 767px) {
    padding-top: 110px;
    padding-bottom: 140px;
  }
     &::before {
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background: rgba(0, 0, 0, 0.6); /* Semi-transparent overlay */
    z-index: 2;  /* Ensures it stays behind the content */
`;
const Image = styled.img`
  max-width: 100%;
  position: relative;
  z-index: 2;
  @media (max-width: 994px) {
    max-width: 80%;
  }
  @media (max-width: 900px) {
    max-width: 80%;
    left: 50px;
  }
`;
const ImageWrapper = styled.div`
  position: absolute;
  top: 95px;
  right: -100px;
  @media (max-width: 994px) {
    top: 205px;
    // left: 0px;
    // right: 100px;
  }
  @media (max-width: 767px) {
    display: none;
  }
`;
const Div = styled.div`
  width: 50%;
  z-index: 2;
  @media (max-width: 994px) {
    width: 80%;
  }
  @media (max-width: 767px) {
    width: 100%;
  }
`;
const animate = keyframes`
0%{
  transform: translateY(0);
 }
 25%{
  transform: translateY(30px);
 }
 50%{
  transform: translateY(-30px);
 }
 100%{
  transform: translateY(0);
 }
`;

const HeadingWrapper = styled.div`
  line-height: 60px;
  z-index: 9999 !important;
  color: #ffffff;
`;
const Heading = styled.div`
  font-size: 30px;
  font-weight: 400;
  color: #ffffff;
  @media (max-width: 767px) {
    font-size: 24px;
  }
  /* margin-bottom:5px; */
`;
const BoldHeading = styled.div`
  font-weight: 900;
  font-size: 80px;
  margin-bottom: 10px;
  line-height: 80px;
  @media (max-width: 994px) {
    font-size: 65px;
  }
  @media (max-width: 767px) {
    font-size: 50px;
    line-height: 50px;
  }
  @media (max-width: 400px) {
    font-size: 40px;
    line-height: 30px;
  }
`;
const LargeText = styled.div`
  font-size: 60px;
  font-weight: normal;
  margin-bottom: 20px;
  line-height: 80px;
  font-weight: 900;
  @media (max-width: 994px) {
    font-size: 45px;
    line-height: 55px;
  }
  @media (max-width: 767px) {
    font-size: 45px;
    margin-bottom: 10px;
  }
  @media (max-width: 576px) {
    font-size: 40px;
    line-height: 45px;
  }
`;
const ParagraphWrapper = styled.div``;
const Para = styled.div`
  font-size: 16px;
  color: #ffffff;
  line-height: 24px;
`;
const BoldPara = styled.div`
  font-size: 20px;
  font-weight: bold;
  margin-bottom: 20px;
  color: #ffffff;
`;
const H1 = styled.h1`
  margin: 0;
  font-size: 20px;
  font-weight: bold;
  display: inline;
`;
const Icons = styled.img`
  position: absolute;
  bottom: 0px;
  ${(p) => p.top && `top:${p.top}`};
  ${(p) => p.left && `left:${p.left}`};
  ${(p) => p.right && `right:${p.right}`};
  ${(p) => p.theme && `bottom:${p.bottom}`};
  ${(p) => p.zindex && `z-index:${p.zindex}`};
  /* animation-timing-function: ease-in;
animation: ${animate} 10s infinite linear; */
`;

const ImageSection = styled.div`
  // position: relative;
  width: 50%;
  @media (max-width: 994px) {
    width: 60%;
  }

  @media (max-width: 767px) {
    // display: none;
    width: 100%;
  }
`;
const FormWrapper = styled.div`
  width: 100%;
  display: flex;
  justify-content: flex-end;
  position: relative;
  z-index: 99;
  @media (max-width: 1150px) {
    justify-content: flex-end;
  }

  @media (max-width: 768px) {
    flex-direction: flex-start;
    margin-top: 50px;
    justify-content: center;
  }
  // @media (max-width: 925px) {
  //   flex-direction: column;
  // }
`;

const Form = styled.form`

  width: 65%;
  border-radius: 12px;
  background-color: #fff;
  padding: 40px 20px 50px 20px;
  display: flex;
  flex-direction: column;
  box-shadow: -6.1px 3.5px 18px 0 rgba(0, 0, 0, 0.16);
  input::-webkit-outer-spin-button,
  input::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }
  input[type="number"] {
    -moz-appearance: textfield;
  }

  @media (max-width: 1150px) {
    width: 80%;
  }
  @media (max-width: 992px) {
    width: 100%;
  }
      @media (max-width: 768px) {
    width: 80%;
  }

  @media (max-width: 576px) {
    width: 100%;
    padding: 30px 20px;
  }
`;

const InputWrapper = styled.div`
  // width: calc(50% - 10px);
  text-align: center;

  width: 100%;
  margin-bottom: 20px;
  @media (max-width: 925px) {
    width: 100%;
  }
`;

const Input = styled(TextField)`
  width: 90%;
  height: 3.2rem;
  background: #ffffff;
  border: 1px solid #dbdbdb;
  padding: 10px;
  outline: none;
  border-radius: 4px;

  @media (max-width: 600px) {
    width: 100%;
  }

  &::placeholder {
    color: #b7b7b7;
    font-weight: 500;
    font-size: 16px;
  }
`;
// const Input = styled.input`
//   width: 90%;
//   height:3.2rem;
//   background: #ffffff;
//   border: 1px solid #dbdbdb;
//   padding: 10px;
//   outline: none;
//   border-radius: 4px;

//   @media(max-width: 600px){
//     width: 100%;
//   }

//   &::placeholder {
//     color: #B7B7B7;
//     font-weight: 500;
//     font-size:16px;
//   }
// `;

const TextArea = styled.textarea`
  width: 90%;
  border: 1px solid #dbdbdb;
  height: 4.8rem;
  padding: 10px;
  border-radius: 4px;
  outline: none;
  resize: none;
  @media (max-width: 600px) {
    width: 100%;
  }
`;

const ErrorMsg = styled.div`
  margin-bottom: -5px;
  font-size: 12px;
  color: #ff0000c2;
  padding-top: 14px;
  text-align: left;
  padding-left: 20px;
`;

const ConsultationWrapper = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
  padding-bottom: 26px;
`;
const Consultationdiv = styled.div`
  font-size: 25px;
  color: #41303e;
  font-weight: bold;
`;

const ButtonWrapper = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
`;

const VideoBgDiv = styled.div`
  position: relative;
  width: 100%;
  overflow: hidden;
  box-sizing: border-box !important;
  video {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    min-width: 100%;
    min-height: 100%;
    object-fit: cover;
    z-index: 1;
  }
`;

const ButtonCover = styled.div`
  box-sizing: border-box;
`;

const Button2 = styled.button`
  // background-color: #30B894;
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-align-items: center;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: center;
  -webkit-justify-content: center;
  -ms-flex-pack: center;
  justify-content: center;
  width: 90%;
  height: 4rem;
  font-family: "Geist";
  font-size: 16px;
  color: white;
  font-weight: 500;
  border: none;
  border-radius: 6px;

  // cursor: pointer;
  cursor: ${(props) => (props.disabled ? "" : "pointer")};
  background-color: ${(props) => (props.disabled ? "#000" : "#30B894")};

  @media (max-width: 576px) {
    width: 100%;
  }
`;

const ContainerNew = styled(Container)`
width:80%;
@media (max-width: 1200px) {
padding:0; 
}
@media(max-width:992px){
flex-direction: column;
gap:20px;
width:85%;}
@media(max-width:576px){

width:90%;}
`;

const HeroSectionNew = () => {
  const [name, setName] = useState("");
  const [email, setEmail] = useState("");
  const [phone, setPhone] = useState("");
  const [message, setMessage] = useState("");
  const [showPopup, setShowPopup] = useState(false);
  const [responseMessage, setResponseMessage] = useState("");
  const [sucessResponse, setSucessResponse] = useState();
  const [emailError, setEmailError] = useState();
  const [popup, setPopup] = useState(false);
  const navigation = useNavigate();
  const [isSubmit, setIsSubmit] = useState(false);
  const navigate = useNavigate();

  const [isHover, setIsHover] = useState(false);

  const validationSchema = Yup.object().shape({
    name: Yup.string()
      .matches(/^[a-zA-Z\s]+$/, "Name must contain only letters")
      .min(3, "must be at least 3 char")
      .max(50, " must be less than 50 char")
      .required("Full Name is required"),
    email: Yup.string()
      .matches(
        /^[a-zA-Z0-9._-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,4}$/,
        "Accept only valid email"
      )
      .email("Invalid email address")
      .required("Email is required"),
    // phone: Yup.string()
    //   .min(10, "Required Valid Phone number")
    //   .max(12, " Not Accept more than 12 Number")
    //   .required("Contact Number is required"),

    phone: Yup.string()
      .required("Please enter your phone number.")
      .test(
        "isValidPhoneNumber",
        "Invalid phone number for the selected country.",
        (value, context) =>
          isValidPhoneNumber(value, context?.parent?.countryCode || "IN")
      ),

    message: Yup.string()
      .required("message is required")
      .min(20, "minimum 20 words required"),
  });

  const formik = useFormik({
    initialValues: {
      name: "",
      email: "",
      message: "",
      phone: "+91-" || "",
      countryCode: "in",
    },
    validationSchema,
    // onSubmit: (values) => {
    //   console.log(values);
    // },
  });

  const handleSubmit = (e) => {
    e.preventDefault();
    setIsSubmit(true);
    // const phoneAsString = String(formik.values.phone);
    const urlParams = new URLSearchParams(window.location.search);
    const queryString = urlParams.toString();

    let number_last = formik?.values.phone?.split("-")?.[1];
    let number_first = formik?.values.phone?.split("-")?.[0];

    let config = {
      method: "post",
      url: `${baseUrl}api/v1/user/saveContactInfo`,
      data: {
        ...formik.values,
        phone: number_last,
        dialCode: number_first,
        keywords: queryString,
      },
    };

    axios
      .request(config)
      .then((response) => {
        formik.resetForm();
        // setMessage("");
        setIsSubmit(false);
        navigate("/thankyou");
        // Swal.fire({
        //   title: "Success",
        //   text: `Thank you for reaching out to us!
        //   Team will get back to you soon`,
        //   icon: "success",
        //   style:{
        //     width: "60%"
        //   }
        // });
        formik.resetForm();
        // console.log(response.data);
      })
      .catch((error) => {
        console.log(error);
        setIsSubmit(false);
        Swal.fire({
          icon: "error",
          text: "Something went wrong!",
        });
      });
  };

  return (
    <VideoBgDiv>
      <Root>
        <video autoPlay loop muted>
          <source src="homepagevdo.mp4" type="video/mp4" />
        </video>
        <ContainerNew style={{ paddingBottom: "8%" }}>
          <Div>
            <HeadingWrapper>
              {/* <Heading>POWERFUL</Heading> */}
              {/* <BoldHeading>TRANSFORMING</BoldHeading> */}
              <LargeText>
                Transforming visions into scalable, secure digital realities
              </LargeText>
            </HeadingWrapper>
            <ParagraphWrapper>
              {/* <BoldPara>
              <H1> Into Scalable, Secure Digital Realities</H1>
            </BoldPara> */}

              <Para>
                Empowering businesses, enterprises, and start-ups to thrive in a
                rapidly evolving digital landscape. Our mission is to transform
                industries by leveraging the power of technology and delivering
                innovative, secure, and scalable solutions.
              </Para>
            </ParagraphWrapper>
            <ButtonCover style={{ boxSizing: "border-box  !important" }}>
              <Button
                mtop="20px"
                color="#fff"
                top="15px"
                bottom="15px"
                left="30px"
                right="30px"
                radius="6px"
                onMouseEnter={() => setIsHover(true)}
                onMouseLeave={() => setIsHover(false)}
                onClick={() => navigation("/contact_us")}
                style={{
                  background: isHover
                    ? "transparent"
                    : "linear-gradient(90deg, #0A44FF -17.74%, #AE0FFF 138.18%)",
                  border: isHover
                    ? "1px solid #fff"
                    : "1px solid rgba(255, 255, 255, 0) ",
                  boxSizing: "border-box  !important",
                  padding: "15px 30px",
                  fontFamily: "Geist",
                  fontWeight: "600",
                  fontSize: "12px",
                }}
              >
                LEARN MORE
              </Button>
            </ButtonCover>
          </Div>

          <ImageSection>
            <FormWrapper>
              <Form>
                <ConsultationWrapper>
                  <Consultationdiv>Consultation Call</Consultationdiv>
                </ConsultationWrapper>

                <InputWrapper>
                  <Input
                    //  id="outlined-basic"
                    label="Name"
                    variant="outlined"
                    type="text"
                    name="name"
                    value={formik.values.name}
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                    required
                  />
                  {formik.touched.name && formik.errors.name && (
                    <ErrorMsg>{formik.errors.name}</ErrorMsg>
                  )}
                </InputWrapper>
                <InputWrapper>
                  <Input
                    label="Email Id"
                    variant="outlined"
                    type="email"
                    placeholder="Email Id"
                    name="email"
                    value={formik.values.email}
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                    required
                  />
                  {formik.touched.email && formik.errors.email && (
                    <ErrorMsg>{formik.errors.email}</ErrorMsg>
                  )}
                </InputWrapper>
                <InputWrapper>
                  {/* <Input
                    label="Phone"
                    variant="outlined"
                    type="number"
                    name="phone"
                    // placeholder="Phone Phone"
                    value={formik.values.phone}
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                    required
                  />
                  {formik.touched.phone && formik.errors.phone && (
                    <ErrorMsg>{formik.errors.phone}</ErrorMsg>
                  )} */}
                  <PhoneInput
                    name="phone"
                    value={formik?.values?.phone}
                    country={formik?.values?.countryCode}
                    countryCodeEditable={false}
                    onChange={(value, country) => {
                      console.log("value, country", value, country);
                      formik.setFieldValue(
                        "phone",
                        `+${country.dialCode}-${value.substring(
                          country.dialCode.length
                        )}`
                      );
                      
                      formik.setFieldValue("countryCode", country.dialCode);
                    }}
                    onBlur={(e) => {
                      formik.handleBlur({ target: { name: "phone" } });
                    }}
                    className="DetailsInfoPhoneInput"
                  />
                   {formik.touched.phone && formik.errors.phone ? (
                  <ErrorMsg>{formik.errors.phone}</ErrorMsg>
                ) : null}
                </InputWrapper>
               

                <InputWrapper>
                  <TextArea
                    type="text"
                    placeholder="Message...."
                    name="message"
                    value={formik.values.message}
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                    rows={5}
                    cols={50}
                    resize="none"
                  />
                  {formik.touched.message && formik.errors.message && (
                    <ErrorMsg>{formik.errors.message}</ErrorMsg>
                  )}
                </InputWrapper>
                <ButtonWrapper>
                  <Button2
                    onClick={handleSubmit}
                    disabled={!formik.isValid || !formik.dirty || isSubmit}
                  >
                    {isSubmit ? <CircularLoader size={20} /> : "SUBMIT"}
                  </Button2>
                </ButtonWrapper>
              </Form>
            </FormWrapper>
          </ImageSection>
        </ContainerNew>
      </Root>
    </VideoBgDiv>
  );
};

export default HeroSectionNew;
