import React from "react";
import styled, { keyframes } from "styled-components";
import { Container } from "../../Component/styledComponents/styledComponents";
import BgImage from "../../Component/Images/banner-bg.jpg";
import GateImage from "../../Component/Images/gate.gif";
import ArrowIcon from "../../Component/Images/green-arrow.png";
import MetaCircle from "../../Component/Images/meta-circle.png";
import DotcircleIcon from "../../Component/Images/dot1.png";
import DotTwoCircleImage from "../../Component/Images/dot3.png";
import { useNavigate } from "react-router-dom";
import Metaverse from "../../Component/Images/metaverse-banner-bg1.jpg";
import MetaverseGameImage from '../../Component/Images/metaverse-banner-boy.png';

const Root = styled.div`
  background-image: url(${BgImage});
  color: #fff;
  padding: 13px 0 60px 0;
  height: 100vh;
`;
const animatedBackground = keyframes`
from { background-position: 0 0; }
to { background-position: 99999% 0; }
`;
export const animate = keyframes`
  0% {
    transform: rotate(-5deg);
  }
  50% {
    transform: rotate(10deg);
  }
  100%{
    transform: rotate(-5deg);
  }

`;
const ImageWrapper = styled.div`
  width: 100%;
  height: 40rem;
  background-image: url(${Metaverse});
  opacity: 0.9;
  background-size: cover;
  background-position: 0px 0px;
  background-repeat: repeat-x;
  animation: ${animatedBackground} 2000s linear infinite;
  display:flex;
  align-items:center;
  justify-content: right;
  padding:20px;
  position:relative;
  @media(max-width:1440px){
    animation: ${animatedBackground} 6000s linear infinite;
}
  @media(max-width:768px){
    height: 30rem;
    animation: ${animatedBackground} 7000s linear infinite;
}
  @media(max-width:500px){
    animation: ${animatedBackground} 9000s linear infinite;
}
`;

const LeftWrapper = styled.div`
  width: 50%;
  @media (max-width: 767px) {
    width: 100%;
  }
`;

const RightWrapper = styled.div`
  width: 50%;
  display: flex;
  align-items: right;
  justify-content: flex-end;
  position: relative;
  @media (max-width: 767px) {
    display: none;
  }
`;

const LargeHeading = styled.h1`
  margin: 0;
  font-size: 100px;
  font-weight: 900;
  @media (max-width: 1000px) {
    font-size: 80px;
  }
  @media (max-width: 767px) {
    font-size: 80px;
  }
  @media (max-width: 500px) {
    font-size: 60px;
  }
`;

const Heading = styled.div`
  font-size: 51px;
  @media (max-width: 767px) {
    font-size: 41px;
  }
`;

const Text = styled.div`
  font-size: 16px;
  margin-top: 20px;
`;

// const Image = styled.img`
//   width: 100%;
//   margin-top: -50px;
// `;

const Image = styled.img`
animation-timing-function: ease-in-out;
animation: ${animate} 5s infinite;
width:500px;
/* position: absolute;
    top: 0px; */
@media(max-width:900px){
  width:340px;
  top:100px;
}
`;

const Icon = styled.img`
  margin-top: 20px;
`;

const Wrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
`;

const H1 = styled.h1`
  margin: 0;
  font-size: 20px;
  font-weight: 400;
`;

const Spin = keyframes`
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
`;
const Clock = keyframes`
  from {
    transform: rotate(360deg);
  }
  to {
    transform: rotate(0deg);
  }
`;
const CircleIcon = styled.img`
  position: absolute;
  left: 80px;
  top: 60px;
  animation: ${Spin} infinite 20s linear;
  @media (max-width: 1044px) {
    width: 120px;
  }
`;
const DotIcon = styled.img`
  position: absolute;
  animation: ${Clock} infinite 10s linear;
  ${(p) =>
    p.width &&
    `
 width:${p.width};
`};
  ${(p) =>
    p.left &&
    `
   left:${p.left};
`};
  ${(p) =>
    p.right &&
    `
   right:${p.right};
`};
  ${(p) =>
    p.top &&
    `
  top:${p.top};
`};
  ${(p) =>
    p.bottom &&
    `
 bottom:${p.bottom};
`};
`;

const MoveIcon = styled.img`
  position: absolute;
  ${(p) =>
    p.width &&
    `
 width:${p.width};
`};
  ${(p) =>
    p.left &&
    `
   left:${p.left};
`};
  ${(p) =>
    p.right &&
    `
   right:${p.right};
`};
  ${(p) =>
    p.top &&
    `
  top:${p.top};
`};
  ${(p) =>
    p.bottom &&
    `
 bottom:${p.bottom};
`};
`;

function MetaverseHeroSection(props) {
  const navigate = useNavigate();
  return (
    <Root>
      <ImageWrapper>
      <Container>
        <Wrapper>
          <LeftWrapper>
              <LargeHeading>Metaverse</LargeHeading>
              <Heading>Development</Heading>
            <Text>
              With our broad spectrum of capabilities and end-to-end Metaverse
              development services, we help businesses leverage the power of
              blockchain technology and achieve next-level security ,
              transparency and decentralization.{" "}
            </Text>
            <Icon
              src={ArrowIcon}
              onClick={() => {
                navigate("/contact_us");
              }}
            />
          </LeftWrapper>
          <RightWrapper>
            {/* <CircleIcon src={MetaCircle} alt="Metaverse Development" />
            <DotIcon src={DotcircleIcon} left="-100px" width="60px" alt="Metaverse Development" />
            <MoveIcon src={DotTwoCircleImage} left="-140px" top="80px" alt="Metaverse Development" />
            <MoveIcon
              src={DotcircleIcon}
              left="60px"
              top="-20px"
              width="30px"
              alt="Metaverse Development"
            />
            <MoveIcon
              src={DotcircleIcon}
              right="160px"
              top="100px"
              width="20px"
              alt="Metaverse Development"
            />
            <DotIcon
              src={DotcircleIcon}
              right="250px"
              top="200px"
              width="50px"
              alt="Metaverse Development"
            />
            <MoveIcon
              src={DotcircleIcon}
              bottom="100px"
              right="40%"
              width="15px"
              alt="Metaverse Development"
            />
            <Image src={GateImage} alt="Metaverse Development" /> */}
            
            {/* <DotIcon src={DotcircleIcon} left="-100px" width="60px" alt="Metaverse Development" /> */}
            {/* <MoveIcon src={DotTwoCircleImage} left="-140px" top="80px" alt="Metaverse Development" /> */}
           
            {/* <Image src={GateImage} alt="Metaverse Development" /> */}
            <Image src={MetaverseGameImage} alt="Metaverse Development" />
          </RightWrapper>
        </Wrapper>
      </Container>
      </ImageWrapper>
    </Root>
  );
}

export default MetaverseHeroSection;
