import React, { useState } from "react";
import BlockchainDevelopment from "./Services/BlockchainDevelopment";
import GameDevelopment from "./Services/GameDevelopment";
import MetaverseDevelopment from "./Services/MetaverseDevelopment";
import MobileAppDevelopment from "./Services/MobileAppDevelopment";
import WebDevelopment from "./Services/WebDevelopment";
import BlockChainIcon from '../../Component/Images/blockchainicon.png';
import GameIcon from '../../Component/Images/game-development-icon.png';
import MobileDevelopmentIcon from '../../Component/Images/mobile-app-development-icon.png';
import WebDeveloperIcon from '../../Component/Images/web-development-icon.png';
import MetaverseDevelopmentIcon from '../../Component/Images/metaverse-development-icon.png';
import { Container } from "../../Component/styledComponents/styledComponents";
import LinkCard from "../../Component/LinkCard";
import { BoldText, ButtonWrapper, Div, HeadingText, Image, Info, InfoWrapper, MobileViewLinkWrapper, Para, Root, Text, Wrapper } from "../../Component/styledComponents/ServicesStyledComponents";
import styled from "styled-components";


const H2 = styled.h2`
margin: 0;
font-size:50px;
font-weight:bold;
margin-bottom:10px;
text-align:center;
@media(max-width:767px){
  font-size:30px;
}
`;

const BussinessService = () => {
  const [servic, setService] = useState("MetaverseDevelopment");

  window.addEventListener('scroll()', ()=>{
    console.log("scrolled")
  })
  return (
    <>
      <Root>
        <Container>
          <Wrapper>
            <HeadingText>Scalable Services</HeadingText>
            <H2>Transform Your Business. </H2>
            <Para>
            Embrace digital transformation, modernize systems, and future-proof your enterprise.            </Para>

            <InfoWrapper>
              <Div>
              <ButtonWrapper
                  selected={servic === 'MetaverseDevelopment'}
                  onClick={() => setService("MetaverseDevelopment")}
                >
                  <Image src={MetaverseDevelopmentIcon} />
                  <Text>Metaverse <br />Development</Text>
                </ButtonWrapper>
                <ButtonWrapper 
                  selected={servic === 'BlockchainDevelopment'}
                  onClick={() => setService("BlockchainDevelopment")}
                >
                  <Image src={BlockChainIcon} />
                  <Text>Blockchain  <br />Development</Text>

                </ButtonWrapper>
                <ButtonWrapper
                  selected={servic === 'GameDevelopment'}
                  onClick={() => setService("GameDevelopment")}
                >
                  <Image src={GameIcon} />
                  <Text> Game <br /> Development</Text>
                </ButtonWrapper>
                <ButtonWrapper
                  selected={servic === 'MobileAppDevelopment'}
                  onClick={() => setService("MobileAppDevelopment")}npm
                >
                  <Image src={MobileDevelopmentIcon} />
                  <Text>Mobile App <br />Development</Text>

                </ButtonWrapper>
                <ButtonWrapper
                  selected={servic === 'WebDevelopment'}
                  onClick={() => setService("WebDevelopment")}
                >
                  <Image src={WebDeveloperIcon} />
                  <Text>Web <br /> Development</Text>

                </ButtonWrapper>
               
              </Div>
              <Info>
                {servic === "BlockchainDevelopment" && <BlockchainDevelopment />}
                {servic === "WebDevelopment" && <WebDevelopment />}
                {servic === "GameDevelopment" && <GameDevelopment />}
                {servic === "MobileAppDevelopment" && <MobileAppDevelopment />}
                {servic === "MetaverseDevelopment" && <MetaverseDevelopment />}
              </Info>
            </InfoWrapper>
            {
                <MobileViewLinkWrapper>
                  <LinkCard 
                   image={BlockChainIcon} 
                   text="Blockchain Development"
                   path='/blockchain_development'
                   />
                  <LinkCard 
                   image={GameIcon} 
                   text="Game Development"
                   path='/game_development'
                   />
                  <LinkCard 
                  image={MobileDevelopmentIcon} 
                  text="Mobile App Development"
                  path='/mobile_app_development'
                  />
                  <LinkCard 
                  image={WebDeveloperIcon} 
                  text="Web Development"
                  path='/web_development'
                  />
                  <LinkCard 
                   image={MetaverseDevelopmentIcon} 
                   text="Metaverse Development"
                   path='/metaverse_development'
                   />
                </MobileViewLinkWrapper>
              }
          </Wrapper>
        </Container>
      </Root>
    </>
  );
};

export default BussinessService;
