import axios from 'axios';
import { useFormik } from "formik";
import * as Yup from "yup";
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { useNavigate } from 'react-router-dom';
import { environmentVariables } from './config/env.config';
const baseUrl = environmentVariables?.apiUrl;

const JobValidationSchema = Yup.object().shape({
  name: Yup.string()
    .max(25, "Name must be 25 characters or less")
    .matches(/^[A-Za-z -]+$/, "Name can only contain letters, spaces, and dashes")
    .required("Name is required"),

  email: Yup.string()
    .email("Please provide a valid email address")
    .required("Email is required"),

  file: Yup.mixed()
    .required("Please upload your CV")
    .test("fileSize", "File is too large", (value) => value && value.size <= 5 * 1024 * 1024) 
    .test("fileType", "Unsupported file format", (value) => value && ["application/pdf", "application/msword", "application/vnd.openxmlformats-officedocument.wordprocessingml.document"].includes(value.type)),

  consent: Yup.bool()
    .oneOf([true], "You must agree to the consent")
    .required("Consent is required"),

  message: Yup.string()
    .min(10, "Message must be at least 10 characters long")
    .required("Message is required"),
});



export const ApplyJob = (initialValues) => {
    const navigate = useNavigate()
    const formik = useFormik({
    initialValues: initialValues,
    validationSchema: JobValidationSchema,
    onSubmit: async (values, { resetForm , setSubmitting  }) => {
       const { message, name, email, file, consent } = values;

      try {
        setSubmitting (true)
        const formData = new FormData();
      
     
      formData.append('id', initialValues.job_id);
      formData.append('name', name);
      formData.append('email', email);
      formData.append('message', message);
      formData.append('resume', file); 
      formData.append('consent', consent);

        const config = {
          method: "post",
          url: `${baseUrl}api/v1/user/register`,
          data: formData,
          headers: {
            'Content-Type': 'multipart/form-data',
          },
        };

        const response = await axios.request(config);
        toast.success("Your application has been submitted successfully!");
        resetForm();
        navigate('/thankyou', { state: { from: 'register for job', message: 'applying at BastionEx' } });
      } catch (error) {
        const errorMessage = error?.response?.data?.message || error?.message;
        toast.error(`Error: ${errorMessage}`);
      }finally {
        setSubmitting(false);
      }
    },
  });

  return formik;
};


const MainConsultationCallSchema = Yup.object().shape({
    name: Yup.string()
      .max(25, "Name must be 25 characters or less")
      .matches(/^[A-Za-z -]+$/, "Name can only contain letters, spaces, and dashes")
      .required("Name is required"),
  
    email: Yup.string()
      .email("Please provide a valid email address")
      .required("Email is required"),

    phone: Yup.string()
      .matches(
        /^[+]?[0-9]{1,4}?[ -]?[0-9]{1,3}?[ -]?[0-9]{4,12}$/, 
        "Phone number is not valid") 
      .required("Phone number is required"),

      companyname: Yup.string()
      .max(50, "Company name must be 50 characters or less")
      .required("Company name is required"),

      select: Yup.string()
      .required("Please select an option"),

      profession: Yup.string()
    .max(50, "Profession must be 50 characters or less")
    .required("Profession is required"),
  
    message: Yup.string()
      .min(10, "Message must be at least 10 characters long")
      .required("Message is required"),
  });

export const MainConsultationCall = (initialValues) => {
    const navigate = useNavigate()
    const formik = useFormik({
    initialValues: initialValues,
    validationSchema: MainConsultationCallSchema,
    onSubmit: async (values, { resetForm , setSubmitting  }) => {

      try {
        setSubmitting (true)
        const config = {
          method: "post",
          url: `${baseUrl}api/v1/user/register`,
          data: {...formik.values},
          };

        const response = await axios.request(config);
        toast.success("Your response has been submitted successfully!");
        resetForm();
       setTimeout(()=>navigate('/'),3000)
      } catch (error) {
        const errorMessage = error?.response?.data?.message || error?.message;
        toast.error(`Error: ${errorMessage}`);
      }finally {
        setSubmitting(false);
      }
    },
  });

  return formik;
};