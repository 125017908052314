import React from "react";
// Import Swiper React components
import styled from "styled-components";
import { Container } from "../../Component/styledComponents/styledComponents";
import { Swiper, SwiperSlide } from "swiper/react";


// Import Swiper styles
import "swiper/css";
import "swiper/css/navigation";

import "./SwiperSlider.css"; // Assuming you are styling the slider yourself

// Import required Swiper modules
import { Navigation } from "swiper";
const Root = styled.div`
  display: flex;
  padding: 150px 0 80px 0;
  @media (max-width: 767px) {
    width: 100%;
    padding: 100px 0 30px 0;
  }
`;
export default function SwiperSlider() {
  const sliderItems = [
    {
      img: "https://careers.salesforce.com/media/gzqimqwd/dsc02173.jpg?rxy=0.4981648236009511,0.3094876009290202&width=450&height=450&rnd=133349363163670000&width=450&height=450&rmode=crop&format=webp&quality=100",
      name: "Rahul",
      designation: "Senior Director, Software Engineering",
      description:
        "At BastionEx, our customers really matter. Their success is ours. When you join our teams, you get to solve challenging problems, work with exceptional teams, and build products from India that are making a global impact. Salesforce is a unique opportunity to acquire deep knowledge and skills that help you build a career.",
    },
    {
      img: "https://careers.salesforce.com/media/oripwsay/india-testimonial.jpg?rxy=0.6086177650768236,0.19686245001881128&width=450&height=450&rnd=133349362888300000&width=450&height=450&rmode=crop&format=webp&quality=100",
      name: "Sonali",
      designation: "Principal Solution Engineer",
      description: `I've been able to chart my own career at Salesforce and have found immense support. Through my leaders, I've had access to opportunities that help me demonstrate my strengths, acquire new skills, and grow in my current role. There's a lot of learning, collaboration, and knowledge share that happens here — and it helps you grow!`,
    },
  ];
  return (
    // <Root>
      <Swiper
        navigation={true} // Enabling navigation
        modules={[Navigation]} // Including the navigation module
        className="mySwiper"
      >
        {" "}
        {sliderItems.map((itemss) => (
          <SwiperSlide id="sliderr">
            <div >
              <div className='sliderBoxContainer'>
                <div className="sliderBox">
                  <div className="SliderImgDivContainer">
                    <div className="SliderImgDiv">
                      <img style={{ width: "100%" }} src={itemss.img} alt="" />
                    </div>
                  </div>
                  <div className="SliderInfoDivContainer">
                    <div>
                      <img
                        style={{ height: "32px", width: "32px" }}
                        src="data:image/svg+xml,%3Csvg width='43' height='32' viewBox='0 0 43 32' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M16.5832 32V16.4672H9.22675C9.22675 10.2774 10.8477 8.87591 16.4585 7.82481V0C5.61086 0.116787 0 6.65693 0 15.1825V32H16.5832ZM42.3932 32V16.4672H35.0367C35.0367 10.2774 36.6576 8.87591 42.2685 7.82481V0C31.4208 0.116787 25.81 6.65693 25.81 15.1825V32H42.3932Z' fill='%230176D3'/%3E%3C/svg%3E%0A"
                        alt=""
                      />
                      <p className="sliderTxt">{itemss.description}</p>
                    </div>
                    <div>
                      <h3 className="sliderName">{itemss.name}</h3>
                      <h4 className="sliderDesignation">
                        {itemss.designation}
                      </h4>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </SwiperSlide>
        ))}
        {/* <SwiperSlide id='sliderr'>
        <div>
            <div>
                <div className='sliderBox'>
                    <div className='SliderImgDivContainer'>
                        <div className='SliderImgDiv'>
                            <img style={{width:'100%'}} src="https://careers.salesforce.com/media/gzqimqwd/dsc02173.jpg?rxy=0.4981648236009511,0.3094876009290202&width=450&height=450&rnd=133349363163670000&width=450&height=450&rmode=crop&format=webp&quality=100" alt="" />
                        </div>
                    </div>
                    <div className='SliderInfoDivContainer'>
                        <div>
                            <img style={{height:'32px',width:'32px'}} src="data:image/svg+xml,%3Csvg width='43' height='32' viewBox='0 0 43 32' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M16.5832 32V16.4672H9.22675C9.22675 10.2774 10.8477 8.87591 16.4585 7.82481V0C5.61086 0.116787 0 6.65693 0 15.1825V32H16.5832ZM42.3932 32V16.4672H35.0367C35.0367 10.2774 36.6576 8.87591 42.2685 7.82481V0C31.4208 0.116787 25.81 6.65693 25.81 15.1825V32H42.3932Z' fill='%230176D3'/%3E%3C/svg%3E%0A" alt="" />
                            <p className='sliderTxt'>At BastionEx, our customers really matter. Their success is ours. When you join our teams, you get to solve challenging problems, work with exceptional teams, and build products from India that are making a global impact. Salesforce is a unique opportunity to acquire deep knowledge and skills that help you build a career.</p>
                        </div> 
                        <div>
                            <h3 className='sliderName'>Rahul</h3>
                            <h4 className='sliderDesignation'>Senior Director, Software Engineering</h4>
                        </div> 
                    </div>
                </div>
            </div>
        </div>
      </SwiperSlide> */}
        {/* <SwiperSlide>Slide 2</SwiperSlide> */}
      </Swiper>
    // </Root>
  );
}
