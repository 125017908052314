import React, { useEffect, useState } from 'react'
import './AllJobs.css'
import { ApiHelper } from '../../ApiHelper';
import { useNavigate } from 'react-router-dom';
import { environmentVariables } from "../../config/env.config";
import { ClipLoader } from 'react-spinners';
const baseUrl = environmentVariables?.apiUrl;

export const AllJobs = () => {
const [data, setData] = useState([])
const [loading, setLoading] = useState(false);
const navigate = useNavigate();

useEffect(()=>{
    const fetchData = async()=>{
        try {
            setLoading(true);
             const type = "all";
             const method = "get";
             const apiUrl = `${baseUrl}api/v1/user/get-jobs?type=${type}`;
             const response = await ApiHelper(apiUrl, method);
             setData(response?.data?.data); 
            
        } catch (error) {
           
            setData([])
            // console.error("Error fetching data:", error);           
        } finally{
            setLoading(false)
        }
    }

    fetchData();
     
},[])

// console.log(data,'kkkkkkk')

if (loading) {
    return (
      <div className="loading-container">
        <ClipLoader color="#36d7b7" loading={loading} size={50} />
        <p>Loading...</p>
      </div>
    );
  }

  if (!data) {
    return (
      <div className="error-container">
        <p>Sorry, no job details available at the moment.</p>
      </div>
    );
  }


  return (
    <div id='jobBox' className='all-jobs'>
       
       <div className="jobBoxR">
       <h1>Opening Jobs</h1>
          {data?.map((jobs) => (
              <div className="jobListBoxContainer">
                <div className="jobBoxList" onClick={() =>navigate(`/jobdescription/?id=${jobs?.id}`)}>
                  <p className="jobTag">{jobs?.category}</p>
                  <h3 className="jobTitle">{jobs?.profile_name}</h3>
                  <div className="jobLocation">
                  <i class="fa-solid fa-location-dot"></i><span>{jobs?.location}</span>
                  </div>
                  {/* <div className="bookmark"><i class="fa-regular fa-bookmark"></i></div> */}
                </div>
              </div>
            ))}
          </div>


    </div>
  )
}
