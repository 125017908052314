import styled from "styled-components";

export const ButtonWrapper = styled.button`
width: 280px;
margin-top: 2rem;
font-family: Roboto;
font-weight: 500;
border-radius:10px;
font-size: 18px;
line-height: 26px;
align-items: center;
background-color: #fff;
justify-content: left;
border: 1px solid;
border:1px solid #00a97c;
opacity: 0.9;
padding:20px 30px;
display: flex;
color: #000;
cursor: pointer;
${(p) => p.selected && `
background-image: linear-gradient(to right, #00a97c,#36c9f9);
border:1px solid #bafceb;
font-weight:bolder;
font-size: 20px;
`};
@media(max-width:900px){
    width: 220px;
}
@media(max-width:767px){
  margin-top: 1rem;
}
`;
export const Div = styled.div`
margin-right:-50px;
width:280px;
min-width:280px;
position: relative;
z-index: 10;
@media(max-width:900px){
    width:220px;
    max-width:220px;
    min-width:220px;
}
@media(max-width:767px){
  display: none;
}
`;

export const Wrapper = styled.div`
display:flex;
align-items: center;
justify-content: center;
flex-direction:column;
padding:0 20px;
@media(max-width:900px){
    padding:0 20px;
}
`;
export const Root = styled.div`
background-color: #fff;
padding:90px 0;
@media(max-width:767px){
  padding:30px 0;
}
`;

export const Image = styled.img`
width:45px;
margin-right:10px;
@media(max-width:767px){
  width:32px;
}
`;
export const Text = styled.div`
text-align: left;

`;
export const HeadingText = styled.div`
font-size: 18px;
font-weight:bold;
`;
export const BoldText = styled.div`
font-size:50px;
font-weight:bold;
margin-bottom:10px;
text-align:center;
@media(max-width:767px){
  font-size:30px;
}
`;
export const Para = styled.div`
text-align: center;
width:55%;
font-size:16px; 
margin-bottom:20px;
@media(max-width:767px){
  width:80%;
}
`;
export const InfoWrapper = styled.div`
width:100%;
display:flex;
height:450px;
position:relative;
justify-content:center;
overflow-x: hidden; 
overflow-y: scroll;
::-webkit-scrollbar{
  width:0px;
  height:0px;

}
::-webkit-scrollbar-thumb{
border-radius:4px;
}
@media(max-width:767px){
  display: none;
}
`;
export const Info = styled.div`
width:calc(100% - 280px);
position: sticky;
top:0;
left:0;
@media(max-width:900px){
    width:calc(100% - 220px);
}
@media(max-width:767px){
  display: none;
}
`;

export const MobileViewLinkWrapper = styled.div`
display: none;
@media(max-width:767px){
display: flex;
width:100%;
flex-direction:column;
}
`;

