import React from "react";
import HeroImage from "../../Component/Images/hero-banner.gif";
import styled, { keyframes } from "styled-components";
import {
  Button,
  Container,
} from "../../Component/styledComponents/styledComponents";
import YellowIcon from "../../Component/Images/yellow.png";
import GreenDot from "../../Component/Images/green-dot.png";
import PinkEtherIcon from "../../Component/Images/pink.png";
import PinkDotIcon from "../../Component/Images/pink-dot.png";
import VoiletBigIcon from "../../Component/Images/voilet-big.png";
import GreendotIcon from "../../Component/Images/green-dot.png";
import ChainIcon from "../../Component/Images/green.png";
import { useNavigate } from "react-router-dom";

const Root = styled.div`
  background-color: #f1f7f5;
  padding-top: 190px;
  @media (max-width: 1200px) {
    padding-bottom: 30px;
  }
  @media (max-width: 994px) {
    padding-top: 140px;
    padding-bottom: 100px;
  }
  @media (max-width: 767px) {
    padding-top: 110px;
    padding-bottom: 140px;
  }
`;
const Image = styled.img`
  max-width: 100%;
  position: relative;
  z-index: 2;
`;
const ImageWrapper = styled.div`
  top: -140px;
  position: relative;
  @media (max-width: 994px) {
    top: -80px;
  }
  @media (max-width: 767px) {
    display: none;
  }
`;
const Div = styled.div`
  width: 50%;
  @media (max-width: 994px) {
    width: 40%;
  }
  @media (max-width: 767px) {
    width: 100%;
  }
`;
const animate = keyframes`
0%{
  transform: translateY(0);
 }
 25%{
  transform: translateY(30px);
 }
 50%{
  transform: translateY(-30px);
 }
 100%{
  transform: translateY(0);
 }
`;

const HeadingWrapper = styled.div`
  line-height: 60px;
`;
const Heading = styled.div`
  font-size: 30px;
  font-weight: 400;
  color: rgba(0, 0, 0, 0.6);
  @media (max-width: 767px) {
    font-size: 24px;
  }
  /* margin-bottom:5px; */
`;
const BoldHeading = styled.div`
  font-weight: 900;
  font-size: 80px;
  margin-bottom: 10px;
  line-height: 80px;
  @media (max-width: 994px) {
    font-size: 65px;
  }
  @media (max-width: 767px) {
    font-size: 50px;
    line-height: 50px;
  }
  @media (max-width: 400px) {
    font-size: 40px;
    line-height: 30px;
  }
`;
const LargeText = styled.div`
  font-size: 80px;
  font-weight: normal;
  margin-bottom: 20px;
  @media (max-width: 994px) {
    font-size: 65px;
  }
  @media (max-width: 767px) {
    font-size: 50px;
    margin-bottom: 10px;
  }
  @media (max-width: 400px) {
    font-size: 40px;
  }
`;
const ParagraphWrapper = styled.div``;
const Para = styled.div`
  font-size: 16px;
  color: rgba(0, 0, 0, 0.6);
`;
const BoldPara = styled.div`
  font-size: 20px;
  font-weight: bold;
  margin-bottom: 20px;

`;
const H1 = styled.h1`
  margin: 0;
  font-size: 20px;
  font-weight: bold;
  display: inline;
`;
const Icons = styled.img`
  position: absolute;
  bottom: 0px;
  ${(p) => p.top && `top:${p.top}`};
  ${(p) => p.left && `left:${p.left}`};
  ${(p) => p.right && `right:${p.right}`};
  ${(p) => p.theme && `bottom:${p.bottom}`};
  ${(p) => p.zindex && `z-index:${p.zindex}`};
  /* animation-timing-function: ease-in;
animation: ${animate} 10s infinite linear; */
`;

const ImageSection = styled.div`
  width: 50%;
  @media (max-width: 994px) {
    width: 60%;
  }
  @media (max-width: 767px) {
    display: none;
  }
`;
const HeroSection = () => {
  const navigation = useNavigate();
  return (
    <Root>
      <Container>
        <Div>
          <HeadingWrapper>
          
            <Heading>POWERFUL</Heading>
            <BoldHeading>BLOCKCHAIN</BoldHeading>
            <LargeText>SOLUTIONS</LargeText>
        
          </HeadingWrapper>
          <ParagraphWrapper>
        
            <BoldPara>A<H1> software development company</H1></BoldPara>
        
            <Para>
              With our broad spectrum of capabilities and end-to-end blockchain
              development services, we help businesses leverage the power of
              blockchain technology and achieve next-level security,
              transparency and decentralization.{" "}
            </Para>
          </ParagraphWrapper>
          <Button
            bgcolor="#000"
            mtop="20px"
            color="#fff"
            top="15px"
            bottom="15px"
            left="30px"
            right="30px"
            radius="30px"
            onClick={() => navigation("/blockchain_development")}
          >
            LEARN MORE
          </Button>
        </Div>
        <ImageSection>
         
          <ImageWrapper>
            <Image src={HeroImage} alt="Bastionex infotech services" />
            <Icons
              bottom="40px"
              right="30%"
              zindex="3"
              src={YellowIcon}
              width="40px"
            />
            <Icons
              bottom="40px"
              right="23%"
              zindex="3"
              src={PinkEtherIcon}
              width="50px"
            />
            <Icons bottom="30px" right="36%" zindex="2" src={GreenDot} />
            <Icons
              bottom="70px"
              right="28%"
              zindex="2"
              src={VoiletBigIcon}
              width="30px"
            />

            <Icons bottom="70px" right="20%" z-index="1" src={GreendotIcon} />
            <Icons bottom="70px" left="26%" zindex="1" src={ChainIcon} />
            <Icons bottom="50px" left="20%" zindex="3" src={VoiletBigIcon} />
            <Icons bottom="70px" left="15%" zindex="3" src={PinkDotIcon} />
          </ImageWrapper>
        </ImageSection>
      </Container>
    </Root>
  );
};

export default HeroSection;
