import { Typography } from "@mui/material";
import { Box, Container, height } from "@mui/system";
import React from "react";
import OwlCarousel from "react-owl-carousel";
import styled from "styled-components";
import "owl.carousel/dist/assets/owl.carousel.css";
import "owl.carousel/dist/assets/owl.theme.default.css";
import AvatarImage1 from "../../Component/Images/avtar1.jpg";
import AvatarImage2 from "../../Component/Images/avtar2.jpg";
import AvatarImage3 from "../../Component/Images/avtar3.jpg";
import AvatarImage4 from "../../Component/Images/avtar4.jpg";
import CompanyLogo from "../../Component/Images/companylogo.png";
import CompanyLogo1 from "../../Component/Images/companylogo1.png";
import CompanyLogo2 from "../../Component/Images/companylogo2.png";
import CompanyLogo3 from "../../Component/Images/companylogo3.png";
import QuoteIcon from "../../Component/Images/quote-icon.png";
import sheluxe from "../../Component/Images/sheluxe-logo.png";
import mondus from "../../Component/Images/mondus-logo.png";

// const QuoteImage = styled.img`
//   width: 40px !important;
//   position: absolute;
//   left: -20px;
//   top: 20px;
// `;
const QuoteImage = styled.img`
  width: 40px !important;
  position: absolute;
  left: -2px;
  top: 8px;
`;
const Card = styled.div`
  width: 100%;
  background-color: #fff;
  padding: 30px 20px;
  min-height: 300px;
  // box-shadow: 0 0px 0px 0 rgba(0, 0, 0, 0.2), 0 3px 5px 0 rgba(0, 0, 0, 0.19);
  position: relative;
  margin-bottom: 10px;
  margin-top: 10px;
  border-radius: 6px;
  border: 1px solid #e5e5e5;
`;
const Root = styled.div`
  background-color: #fff;
  padding: 80px 0 120px 0;
  @media (max-width: 767px) {
    padding: 10px 0 70px 0;
  }
`;
const Image = styled.img`
  max-width: 100px !important;
  margin-top: 20px;
  max-height: 70px;
  object-fit: fit-content;
`;

const Div = styled.div`
  color: #000;
  font-family: Geist;
  font-size: 18px;
  line-height: 1.5;
  letter-spacing: 0.5px;
  font-weight: 400;
  text-align: left;
  margin-top: 15px;

  ${(p) =>
    p.Infodiv &&
    `  
    color:#909090;
    font-size:14px;
     margin-top: 5px;
    `}
`;
const Carousel = styled(OwlCarousel)`
  width: 100%;
  display: flex;
  /* position:relative; */
  /* padding: 0 20px; */

 
  & .owl-carousel .owl-nav.disabled,
  .owl-carousel .owl-dots.disabled {
    display: flex;
  }


  & .owl-next , & .owl-prev{
  background:linear-gradient(90deg, #0A44FF -17.74%, #AE0FFF 138.18%) !important;
  width:40px;
  height:40px;
  border-radius:50%;
  font-size:30px;
  color:#fff;
  }

  .owl-nav{
  right: 7%;
    gap: 20px;
    display: flex;
    top: -20%;
    position: absolute;
    
   @media (max-width: 992px) {
  top:-16%;
  }
   @media (max-width:800px) {
  top:-20%;
      margin-left: 3%;
}
      
  @media(max-width:700px){
  position: relative;
    justify-content: center;
    }
  }


  }

  .arrow i{
  color:#fff;
  }


  @media (max-width: 767px) {
    padding: 0px 0px;
  }
`;
const InfoWrapper = styled.div`
  display: flex;
  align-items: center;
  @media (max-width: 767px) {
    flex-direction: column;
    justify-content: center;
  }
`;

const Info = styled.div`
  font-size: 14px;
  line-height: 1.5;
  letter-spacing: 0.5px;
  font-weight: 600;
  text-align: left;
  margin-left: 0px;
  @media (max-width: 767px) {
    width: 100%;
  }
`;
const Avatar = styled.img`
  width: 70px !important;
  height: 70px !important;
  border-radius: 50%;
`;

const ImageWrapper = styled.div`
  width: 80px;
  height: 80px;
  background-color: #f8f8f8;
  border-radius: 50%;
`;
function Clients() {
  const options = {
    margin: 60,
    responsiveClass: true,
    nav: true,
    dots: false,
    autoplay: true,
    center: true,
    stagePadding: 350,
    navText: [
      `<div class="arrow">
       <i class="fa-solid fa-chevron-left"></i>
       </div>`,
      `
    <div class="arrow">
    <i class="fa-solid fa-chevron-right"></i>
    </div>`,
    ],
    smartSpeed: 700,
    loop: true,
    responsive: {
      0: { items: 1, stagePadding: 20 },
      400: { items: 1, stagePadding: 20 },
      500: { items: 1, stagePadding: 100 },
      800: { items: 1, stagePadding: 100 },
      900: { items: 1, stagePadding: 130 },
      1024: { items: 1, stagePadding: 140 },
      1200: {items: 1, stagePadding:250 },
      1600: {items: 1, stagePadding:360 }

    },
  };

  return (
    <Root>
      <Box sx={{ height: "100%", textAlign: "center" }}>
        <Box sx={{ margin: "2rem 0rem 2rem 0rem" }}>
          <Typography
            sx={{
              color: "#323232",
              fontWeight: "500",
              fontSize: "36px",
              fontFamily: "Geist",
            }}
          >
            What our clients say
          </Typography>
        </Box>

        <Carousel {...options}>
          <Card>
            {/* <QuoteImage src={QuoteIcon} /> */}
            <InfoWrapper>
              {/* <Avatar
                  src={AvatarImage2}
                  alt="Bastionex Infotech Testimonials"
                /> */}
              <Info>
                Ankit Singh
                <Div Infodiv>Client Relationship Manager at</Div>
              </Info>
            </InfoWrapper>
            <Div>
              "BastionEx has been a game-changer for our financial operations.
              Their robust security solutions and seamless integration have
              significantly enhanced our peace of mind. Highly recommend them to
              any business prioritizing data protection."
            </Div>

            <Image src={CompanyLogo} />
          </Card>
          <Card>
            {/* <QuoteImage src={QuoteIcon} /> */}
            <InfoWrapper>
              {/* <Avatar
                  src={AvatarImage1}
                  alt="Bastionex Infotech Testimonials"
                /> */}
              <Info>
                Mehreen Sheikh
                <Div Infodiv>Founder of Sheluxe</Div>
              </Info>
            </InfoWrapper>
            <Div>
              "We were initially skeptical about outsourcing our security needs,
              but BastionEx quickly changed our minds. Their team of experts is
              not only knowledgeable but also incredibly responsive. Their
              proactive approach to security has saved us from potential
              breaches."
            </Div>
            <Image src={sheluxe} />
          </Card>
          <Card>
            {/* <QuoteImage src={QuoteIcon} /> */}
            <InfoWrapper>
              {/* <ImageWrapper>
                  <Avatar
                    src={AvatarImage2}
                    alt="Bastionex Infotech Testimonials"
                  />
                </ImageWrapper> */}
              <Info>
                Sandeep Chachra
                <Div Infodiv>Founder of Mondus Properties</Div>
              </Info>
            </InfoWrapper>
            <Div>
              "Working with BastionEx to create our website was a truly seamless
              experience. Their team was incredibly professional and efficient,
              and they were able to bring our vision to life. The platform is
              user-friendly, visually appealing, and has significantly improved
              our online presence. I highly recommend BastionEx to any business
              looking to elevate their digital footprint."
            </Div>
            <Image src={mondus} />
          </Card>
          <Card>
            {/* <QuoteImage src={QuoteIcon} /> */}
            <InfoWrapper>
              {/* <ImageWrapper>
                  <Avatar
                    src={AvatarImage4}
                    alt="Bastionex Infotech Testimonials"
                  />
                </ImageWrapper> */}
              <Info>
                James
                <Div Infodiv>Chief Executive Officer at Valour</Div>
              </Info>
            </InfoWrapper>
            <Div>
              "As a small business, we couldn't afford a dedicated in-house
              security team. BastionEx provided a cost-effective solution that
              scaled with our growth. Their managed security services have given
              us the confidence to focus on our core business."
            </Div>
            <Image src={CompanyLogo3} />
          </Card>
        </Carousel>
      </Box>
    </Root>
  );
}
export default Clients;
