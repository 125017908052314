import { React, useState } from "react";
import axios from "axios";

export const ApiHelper = async (apiUrl, method) => {
  try {
    const config = {
      url: apiUrl,
      method: method,
    };
    const response = await axios.request(config);
    return response;
  } catch (error) {
    console.log("error", error);
    return error?.response?.data?.message;
  }
};
export const ApiHelperID = async (method, apiUrl, id) => {
  try {
    const config = {
      url: `${apiUrl}?id=${id}`,
      method: method,
    };
    const response = await axios.request(config);
    return response;
  } catch (error) {
    console.log("error", error);
    return error?.response?.data?.message;
  }
};
