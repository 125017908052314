import React, { useState, useEffect } from "react";
import Accordion from "./CareerAccordion";
import Joblist from "./JobList";
import styled from "styled-components";
import { Container } from "../../Component/styledComponents/styledComponents";
import SwiperSlider from "./SwiperSlider";
import "./JobList.css";
import axios from "axios";
import { ApiHelper } from "../../ApiHelper";
import { environmentVariables } from "../../config/env.config";
import { useNavigate } from "react-router-dom";
const baseUrl = environmentVariables?.apiUrl;

const Root = styled.div`
  // display: flex;
  overflow: hidden;
  padding: 0 0 80px 0;
  @media (max-width: 767px) {
    width: 100%;
    // padding: 100px 0 30px 0;
    padding: 56px 0 30px 0;
  }
`;

const Main = styled.div`
  box-sizing: border-box;
  overflow: hidden;
  @media (max-width: 500px) {
    padding-inline: 20px !important;
  }
`;
const HeroContainer = styled.div`
  position: relative;
  background-image: linear-gradient(to bottom, #c6e9ff 0%, #fff 75%);
  color: #032d60;
  background-color: #fff;
  overflow: hidden;
  font-family: "Geist", sans-serif;
  @media (min-width: 992px) {
    display: flex;
    align-items: center;
  }
`;
const Hero = styled.div`
  width: 100%;
  margin-inline: auto;

  media (min-width: 576px) {
    padding-right: 0;
    padding-left: calc((100% - 510px) / 2);
  }
  @media (min-width: 768px) {
    padding-left: calc((100% - 690px) / 2);
  }
  @media (min-width: 992px) {
    padding-left: calc((100% - 930px) / 2);
  }

  @media (min-width: 1200px) {
    padding-left: calc((100% - 1200px) / 2);
  }

  @media (min-width: 1400px) {
    padding-left: calc((100% - 1290px) / 2);
  }
`;
const HeroDiv = styled.div`
  display: flex;
  width: 100vw;
  // flex-wrap: wrap;
  margin-inline: 0;
  justify-content: center;
  @media (max-width: 500px) {
    flex-direction: column;
  }
  @media (min-width: 768px) {
    width: 100%;
  }
  @media (min-width: 992px) {
    flex-direction: row-reverse;
    width: 100vw;
  }
  div {
    padding-inline: 0;
  }
`;
const HeroDiv1 = styled.div`
  padding-bottom: 4rem;
  position: relative;
  max-width: 100%;
  @media (max-width: 500px) {
    padding-bottom: 0;
  }
  @media (min-width: 992px) {
    flex: 0 0 auto;
    width: 58.33333333%;
  }
`;
const Picture = styled.div`
    display: block;
    position: relative;
    z-index: 1;
    padding-left:3rem;
    img{
    clip-path: circle(58% at 50% 26%)
    }
    img::after{
    content: "";
    position: absolute;
    right: 0;
    bottom: 0;
    width: 212px;
    height: 167px;
    background-image: url('https://careers.salesforce.com/images/default-hero-foliage/bushes.png');
    background-repeat: no-repeat;
    background-position: bottom right;
    background-size: contain;
    width:100%;
    float: right;
    @media (max-width: 500px){
    padding:0;
    }
    @media (min-width: 768px){
        width: 422px;
        height: 291px;
      }
    }
    }
`;
const InsectWrapper = styled.div`
  height: 550px;
  width: 350px;
  left: 60px;
  transform: translateX(-100%);
  top: 0;
`;
const HeroDiv2 = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  padding: 4rem 32px 4rem 0;
  @media (max-width: 500px) {
    padding: 0rem 32px 1rem 0;
  }
  @media (min-width: 992px) {
    flex: 0 0 auto;
    width: 35.33333333%;
  }
  h1 {
    padding-top: 0;
    margin-bottom: 1.5rem;
    line-height: 1.14;
    letter-spacing: -0.018em;
    @media (max-width: 500px) {
      text-align: center;
    }
  }
  @media (min-width: 1200px) {
    h1 {
      font-size: 3.5rem;
    }
  }
  @media (min-width: 992px) {
  }
`;
const ButtonContainer = styled.div`
  justify-content: center;
  width: 100%;
  margin-top: 3rem;
  margin-bottom: -25px;
  display: flex;
  a {
    margin-right: 2rem;
    text-align: center;
    margin-bottom: 1.5rem;
    cursor: pointer;
    padding: 13px 32px;
    outline: none;
    font-weight: 700;
    // border: 2px solid #0176d3;
    border-radius: 0.25rem;
    @media (max-width: 500px) {
      padding: 13px 31px;
      margin-top: -1rem;
    }
    @media (min-width: 576px) {
      margin-right: 1rem;
      padding: 13px 24px;
    }
    @media (min-width: 992px) {
      margin-right: 5px;
    }
    @media (min-width: 1200px) {
      margin-right: 1rem;
      padding: 13px 24px;
    }
  }
`;
const Banner = styled.div`
  width: 100%;
  padding-top: 4rem;
  padding-bottom: 4rem;
  text-align: center;

  @media (max-width: 500px) {
    padding-top: 2.25rem;
    padding-bottom: 2.25rem;
  }
`;
const BannerContainer = styled.div`
  margin-inline: auto;

  a {
    position: relative;
    img {
      width: 75%;
      border-radius: 8px;
      margin-inline: auto;
      @media (max-width: 500px) {
        width: 100%;
      }
    }
  }
  @media (min-width: 992px) {
    width: 960px;
  }
  @media (min-width: 1200px) {
    width: 1140px;
  }
  @media (min-width: 1400px) {
    width: 1320px;
  }
`;
const BannerTextContainer = styled.div`
  margin-bottom: 4rem;
  justify-content: center;
  text-align: center;
  display: flex;
  flex-wrap: wrap;
  font-family: "Geist", sans-serif;
  @media (max-width: 500px) {
    margin-bottom: 2.5rem;
  }
  div {
    max-width: 100%;
    @media (min-width: 768px) {
      flex: 0 0 auto;
      width: 66.66%;
    }
    h2 {
      font-size: 48px;
      color: #032d60;
      margin-bottom: 24px;
      @media (max-width: 550px) {
        font-size: 40px;
      }
    }
    p {
      font-size: 18px;
      color: #181818;
      @media (max-width: 500px) {
        font-size: 17px;
      }
    }
  }
`;
const AwardSection = styled.div`
  section {
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    place-items: center;
    gap: 0.5rem;
    @media (max-width: 500px) {
      grid-template-columns: repeat(1, 1fr);
    }
    div {
      width: 100%;
      padding-inline: 1rem;
      img {
        width: 100%;
        border-radius: 8px;
      }
    }
  }
`;
const WomenSection = styled.div`
  display: flex;
  align-items: center;

  div {
    width: fit-content;
  }
`;
const WalkingWomen = styled.div`
  position: relative;
  img {
    clip-path: circle(50% at 50% 50%);
    width: 90%;

    &::after {
      content: "";
      position: absolute;
      width: 25%;
      height: 35%;
      top: 5%;
      right: 0;
      transform: rotate(-20deg);
      background-image: url("https://careers.salesforce.com/images/rti-after.png");
      background-repeat: no-repeat;
      background-position: center;
      background-size: contain;
      z-index: -1;
    }
  }
`;
const LearnMore = styled.div`
  display: grid;
  align-items: stretch;
  grid-template-columns: repeat(3, 1fr);
  // place-items:center;
  padding-inline: 12px;
  section {
    display: flex;
    align-items: stretch;
    flex-direction: column;
    justify-content: left;
    box-shadow: 0 0.125rem 0.5rem -0.125rem rgba(24, 24, 24, 0.08),
      0 0.5rem 0.75rem -0.125rem rgba(24, 24, 24, 0.16);
    margin-inline: 18px;
    border-radius: 12px;
    background: #fff;
    height: 100%;
    max-height: -webkit-max-content;
    div {
      img {
        width: 100%;
        border-top-right-radius: 12px;
        border-top-left-radius: 12px;
        position: relative;
        top: 3px;
      }
    }
  }

  @media (max-width: 992px) {
    // display: flex;
    // flex-wrap: nowrap;
    grid-template-columns: 1fr 1fr;
    row-gap: 30px;
    // overflow-x: scroll
    // flex: 1  1;
  }

  @media (max-width: 500px) {
    grid-template-columns: 1fr;
  }
`;
const CardBody = styled.div`
  padding: 2rem 1.5rem 1.25rem;
  display: flex;
  flex-direction: column;
  text-align: left;
  background: #fff;
  border-bottom-right-radius: 12px;
  border-bottom-left-radius: 12px;
  height: -webkit-fill-available;
  max-height: 350px;
`;
const CardTags = styled.div`
  font-size: 0.75rem;
  color: #032d60;
  font-weight: 700;
  border-radius: 2.5rem;
  width: max-content;
  padding: 0.5rem 1rem;
  background-color: #eaf5fe;
  margin-bottom: 0.75rem;
`;
const CardTitle = styled.div`
  margin-bottom: 0.75rem;
  font-size: 1.5rem;
  color: #032d60;
  font-weight: 500;
  font-family: Geist;
`;
const CardText = styled.div`
  font-family: Instrument Sans;
  font-size: 0.875rem;
  margin-bottom: 0.75rem;
  letter-spacing: 0.0012em;
`;
const LearnMoreTxt = styled.div`
  font-family: Instrument Sans;
  margin-top: auto;
  width: max-content;
  font-size: 0.875rem;
  font-weight: 700;
  color: #032d60;
  letter-spacing: 0.0012em;
  cursor: pointer;
  a {
    text-decoration: none;
    color: blue;
    font-family: Instrument Sans;
  }
`;
const Imgdiv = styled.div`
  height: 240px;
  min-height: 240px;
  max-height: 240px;

  img {
    object-fit: cover;
    height: 100%;
  }
`;

const Careers = () => {
  const [data, setData] = useState([]);
  const [loading, setLoading] = useState(false);
  const navigate = useNavigate();

  const fetchData = async () => {
    try {
      setLoading(true);
      const type = 3;
      const method = "get";
      const apiUrl = `${baseUrl}api/v1/user/get-jobs?type=${type}`;
      const response = await ApiHelper(apiUrl, method);
      setData(response?.data?.data);
      setLoading(false);
    } catch (error) {
      setData([]);
      setLoading(false);
    }
  };

  useEffect(() => {
    fetchData();
  }, []);

  return (
    <Root>
      {/* <Container> */}
      <div id="heroSection">
        <div className="heroR">
          <img src="/career-main.jpg" alt="Career Main" />
        </div>
        <div className="heroL">
          <div className="heroLcontent">
            <div className="heroTitle">
              <h4>Seeking and Building the Extraordinary</h4>
            </div>
            {data && (
              <div className="heroBtns">
                <a className="viewBtn" onClick={() => navigate("/all_jobs")}>
                  View Jobs
                </a>

                {/* <a className="joinBtn" href="">Join our Talent Community</a> */}
              </div>
            )}
          </div>
        </div>
      </div>

      <Main>
        {/* job list section */}
        <Banner
          style={{
            background: "url(/svgbg.svg)",
            backgroundRepeat: "no-repeat",
            backgroundSize: "auto",
            backgroundPosition: "left center",
          }}
        >
          <BannerContainer>
            <Joblist data={data} />
          </BannerContainer>
        </Banner>

        <Banner>
          <BannerContainer>
            <BannerTextContainer>
              <div>
                <h2 style={{ marginBottom: "24px" }}>
                  Innovating. Collaborating. Inspiring.
                </h2>
                <h6
                  style={{ margin: "0", fontWeight: "400", fontSize: "1.5rem" }}
                >
                  Curious about what drives us forward? Let’s connect!
                </h6>
              </div>
            </BannerTextContainer>
            <LearnMore>
              <section>
                <Imgdiv>
                  <img src="/bastionex1.jpg"></img>
                </Imgdiv>
                <CardBody>
                  <CardTags>Diwali Celebration</CardTags>
                  <CardTitle>
                    From us at Bastionex, here’s to illuminating solutions,
                    bright ideas, and endless opportunities this Diwali!
                  </CardTitle>
                  {/* <CardText>
                    {" "}
                    At Salesforce, internships are a launchpad for successful
                    careers, as seen in Lasya Priya Karuturi's journey from
                    intern to full-time Customer Success role. Starting as an
                    Associate Technical Support Engineer, she gained hands-on
                    experience and mentorship.
                  </CardText> */}
                  <LearnMoreTxt>
                    <a
                      href="https://www.instagram.com/p/DBwRTd7ylZp/?img_index=9"
                      target="_blank"
                    >
                      Read More
                    </a>
                  </LearnMoreTxt>
                </CardBody>
              </section>
              <section>
                <Imgdiv>
                  <img src="/bastionex2.jpg"></img>
                </Imgdiv>
                <CardBody>
                  <CardTags>Career Growth</CardTags>
                  <CardTitle>
                    Sharing some positive vibes and wishes from our techies!
                  </CardTitle>
                  {/* <CardText>
                    {" "}
                    From overcoming self-doubt to managing a global team,
                    discover six key leadership insights from a Salesforce India
                    product management leader.{" "}
                  </CardText> */}
                  <LearnMoreTxt>
                    <a
                      href="https://www.instagram.com/p/DERQVopy_8r/?img_index=1"
                      target="_blank"
                    >
                      Learn More
                    </a>
                  </LearnMoreTxt>
                </CardBody>
              </section>
              <section>
                <Imgdiv>
                  <img src="/bastionex3.jpg"></img>
                </Imgdiv>
                <CardBody>
                  <CardTags>Independence Day</CardTags>
                  <CardTitle>
                    Celebrating the spirit of Independence and the power of
                    dedication!
                  </CardTitle>
                  {/* <CardText>
                  🇮🇳 Today, we honoured our nation's freedom while recognizing the relentless efforts of our outstanding employees. Here's to the team that drives our success every day—together, we continue to build a brighter, more prosperous future.
                  </CardText> */}
                  <LearnMoreTxt>
                    <a
                      href="https://www.instagram.com/p/C-pziUuPeI3/?img_index=8"
                      target="_blank"
                    >
                      Learn More
                    </a>
                  </LearnMoreTxt>
                </CardBody>
              </section>
            </LearnMore>
            <ButtonContainer style={{ color: "#0176d3", padding: "9px 24px" }}>
              <a
                style={{
                  backgroundColor: "#fff",
                  border: " 1px solid blue",
                  textDecoration: "none",
                  color: "blue",
                }}
                href="https://www.instagram.com/bastionex_infotech/"
                target="_blank"
              >
                View all posts  <i class="fa-solid fa-arrow-right"></i>{" "}
              </a>
            </ButtonContainer>
          </BannerContainer>
        </Banner>
       
      
        {/* Join community */}
        {data && (
          <Banner>
            <BannerContainer>
              <BannerTextContainer>
                <div>
                  <h2 style={{ marginBottom: "24px" }}>Ready to Join Us? </h2>
                  <p>
                    <span>Take the first step toward an exciting career </span>
                  </p>
                </div>
              </BannerTextContainer>
              <ButtonContainer
                style={{ color: "#fff", justifyContent: "center" }}
              >
                <a
                  style={{
                    background:
                      "linear-gradient(90deg, rgb(10, 68, 255) -17.74%, rgb(174, 15, 255) 138.18%)",
                    marginRight: 0,
                  }}
                  onClick={() => navigate("/all_jobs")}
                >
                  Apply Now
                </a>
              </ButtonContainer>
            </BannerContainer>
          </Banner>
        )}
      </Main>
      {/* </Container> */}
    </Root>
  );
};

export default Careers;
