import React from "react";
import styled from "styled-components";

const H3 = styled.h3`
  margin: 0;
  align-items: center;
  font-weight: 900;
  font-size: 60px;
  font-family: "Roboto";
  margin-bottom: -15px;
`;
const MainContainer = styled.div`
  text-align: center;
  line-height: 30px;
  padding: 5.8rem 0rem 0rem 0rem;
  padding-bottom:110px;
  background-color: #fff;
`;

const TextWrappersmall = styled.text``;

function HiringSection() {
  return (
    <div>
      <MainContainer>
        <H3>We're Hiring!</H3>
        <br />
        <TextWrappersmall>
          Drop us a email at{" "}
          <a
            href="hr@bastionex.net"
            style={{ color: "#00A97C", fontWeight: "500" }}
          >
            hr@bastionex.net
          </a>
        </TextWrappersmall>
      </MainContainer>
    </div>
  );
}

export default HiringSection;
