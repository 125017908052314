import React from 'react'
import './NewService.css'

export const NewService = () => {
  return (
  <div className='digital-wrapper'>
      <div className='digital'>
<div className='main-heading'>
<h1>Digital Solutions for
forward-thinking <span>companies <img className='line' src="/cut-line.png" alt="" /> <img className='line-text' src="/people.png" alt="" /><img className='line-arrow' src="/up-arrow.png" alt="" /></span></h1>
</div>
<div className="cards-wrapper">
<div className="card card1">
  <h4>Unlocking artificial intelligence</h4>
  <p> We build custom AI solutions to boost your business efficiency & innovation.</p>
  <img src='/AI.jpg'></img>
  {/* <video 
    autoPlay 
    muted 
    playsInline
    loop 
    src="https://cdn.prod.website-files.com/65d6f8bb6073c98a3f93922c/65dbb656a372e1937327896f_-d24a-4b5f-8631-6bdf9fcb3205-transcode.mp4"
  >
    <source 
      src="https://cdn.prod.website-files.com/65d6f8bb6073c98a3f93922c/65dbb656a372e1937327896f_-d24a-4b5f-8631-6bdf9fcb3205-transcode.mp4" 
      type="video/mp4" 
    />
    <source 
      src="https://cdn.prod.website-files.com/65d6f8bb6073c98a3f93922c/65dbb656a372e1937327896f_-d24a-4b5f-8631-6bdf9fcb3205-transcode.webm" 
      type="video/webm" 
    />
    Your browser does not support the video tag.
  </video> */}
</div>
<div className="card card2">
  <h4>Experience seamless mobile experiences</h4>
  <p>We craft engaging apps for iOS & Android that drive user engagement. </p>
  
  <img src='/new-service.jpg'></img>
  {/* <video 
    autoPlay 
    muted 
    playsInline
    loop 
    src="https://cdn.prod.website-files.com/65d6f8bb6073c98a3f93922c/65e1d6a2ae1ebd39382a39bb_Websites-transcode.mp4"
  >
    <source 
      src="https://cdn.prod.website-files.com/65d6f8bb6073c98a3f93922c/65e1d6a2ae1ebd39382a39bb_Websites-transcode.mp4" 
      type="video/mp4" 
    />
    <source 
      src="https://cdn.prod.website-files.com/65d6f8bb6073c98a3f93922c/65e1d6a2ae1ebd39382a39bb_Websites-transcode.webm" 
      type="video/webm" 
    />
  </video> */}
</div>
<div className="card card3">
  <h4>Websites that stand out</h4>
  <p> We design & develop stunning, user-friendly websites that convert visitors into customers.</p>
  <img src='/new-service-2.jpg'></img>
  {/* <video 
    autoPlay 
    muted 
    playsInline
    loop 
    src="https://cdn.prod.website-files.com/65d6f8bb6073c98a3f93922c/65dbb656a372e1937327896f_-d24a-4b5f-8631-6bdf9fcb3205-transcode.mp4"
  >
    <source 
      src="https://cdn.prod.website-files.com/65d6f8bb6073c98a3f93922c/65dbb656a372e1937327896f_-d24a-4b5f-8631-6bdf9fcb3205-transcode.mp4" 
      type="video/mp4" 
    />
    <source 
      src="https://cdn.prod.website-files.com/65d6f8bb6073c98a3f93922c/65dbb656a372e1937327896f_-d24a-4b5f-8631-6bdf9fcb3205-transcode.webm" 
      type="video/webm" 
    />
    Your browser does not support the video tag.
  </video> */}
</div>

</div>


    </div>
  </div>
  )
}
