import React from "react";
import { useNavigate } from "react-router-dom";
import styled from "styled-components";
import "./JobList.css";

const Root = styled.div`
  display: flex;
  padding: 150px 0 80px 0;
  @media (max-width: 767px) {
    width: 100%;
    padding: 100px 30px 0;
  }
`;
export default function JobList({ data }) {
  const navigation = useNavigate();

  return (
    // <Root>
    <div>
      <div id="jobBox">
        <div className="jobBoxL">
          <div className="jobBoxLcontainer">
            <div className="headingJob">
              <h2>Build the Future with Us</h2>
            </div>
            <div className="descriptionJob">
              <p>
                Bringing together the brightest minds to create groundbreaking
                solutions in blockchain and IT development. With an
                ever-expanding focus on innovation, we are committed to driving
                global digital transformations and pushing the boundaries of
                what’s possible.
              </p>
              <br />
              <p>
                If you’re passionate about creating impactful tech products,
                solving complex challenges, and being part of a forward-thinking
                team, this is where your journey begins.
              </p>
            </div>
            {data && (
              <div className="buttonJob">
                <a href="" onClick={() => navigation("/all_jobs")}>
                  View all jobs
                </a>
              </div>
            )}
          </div>
        </div>
        <div className="jobBoxR">
          {data?.map((jobs) => (
            <div className="jobListBoxContainer">
              <div
                className="jobBoxList"
                onClick={() => navigation(`/jobdescription/?id=${jobs?.id}`)}
              >
                <p className="jobTag">{jobs?.category}</p>
                <h3 className="jobTitle">{jobs?.profile_name}</h3>
                <div className="jobLocation">
                  <i
                    style={{ marginLeft: "0" }}
                    class="fa-solid fa-briefcase"
                  ></i>{" "}
                  <span>{jobs?.experience} </span> |{" "}
                  <i class="fa-solid fa-indian-rupee-sign"></i>{" "}
                  <span>Not Disclosed</span> |{" "}
                  <i class="fa-solid fa-location-dot"></i>
                  <span>{jobs?.location}</span>
                </div>
                {/* <div className="bookmark"><i class="fa-regular fa-bookmark"></i></div> */}
              </div>
            </div>
          ))}
        </div>
      </div>
      {/* trial */}
      {/* <div id="heroSection">
          <div className="heroR">
          <img src="https://careers.salesforce.com/media/k3qmvilc/ee_cgc_2022_-hyderabad-_group_bhumika_sharma_himanshu_kambe_johannah_samuel_hull_deepa_n_5052-1.jpg?cc=0,0.20576493521476413,0,0.048708225819033106&width=940&height=627&rnd=133403920127000000&width=940&height=627&rmode=crop&format=webp&quality=100"></img>
          </div>
          <div className="heroL">
            <div className="heroLcontent">
              <div className="heroTitle"><h4>BastionEx in India</h4></div>
              <div className="heroBtns">
                <a className='viewBtn' href="">View Jobs</a>
                <a className='joinBtn' href="">Join our Talent Community</a>
              </div>
            </div>
          </div>
        </div> */}
    </div>
    // </Root>
  );
}
