export const HOME = '/';
export const SERVICES = '/services';
export const ABOUTUS = '/aboutus';
export const THANKYOU = '/thankyou';
export const DEFAULT = '*';
export const CONTACTUS = '/contact_us';
export const POJECT_PAGE = '/project';
export const BLOCKCHAIN = '/blockchain_development';
export const GAME = '/game_development';
export const MOBILE = '/mobile_app_development';
export const WEBDEVELOPEMENT = '/web_development';
export const APPDEVELOPMENT = '/mobile_app_development';
export const METAVERSEDEVELOPMENT = '/metaverse_development';
export const OURWORK = '/our_work';
export const TERMSANDCONDITIONS = '/termsandcondition';
export const PRIVACYANDPOLICY = '/privacyandpolicy';
export const ETHEREUM = '/ethereum';
export const CRYPTOCURRENCY = '/crypto_currency';
export const NFTMARKETPLACE = '/nft_marketplace';
export const NFT = '/nfts';
export const EXCHANGECRYPTO = '/exchangecrypto';
export const CAREERS = '/careers';
export const JOBDESCRIPTION = '/jobdescription';
export const APPLICATION = '/application';
export const SWIPER = '/swiper';
export const ALLJOBS = '/all_jobs'


