import React from 'react';
import BelivingCompanies from './BelivingCompanies';
import BussinessService from './BussinessService';
import ClassServices from './ClassServics';
import Clients from './Clients';
import Coustumerstories from './Coustumerstories';
import HeroSection from './HeroSection';
import Services from './Services';
import MetaverseSection from './MetaverseSection';
import ConnectMessage from '../../Component/ConnectMessage';
import HeroSectionNew from './HeroSectionNew';
import NewAccordian from './NewAccordian'
import MapLocation from '../ContactUs/MapLocation';
import { NewService } from '../../Component/NewServices/NewService';

const Home = () =>{
    return(
        <div style={{overflow:"hidden"}}>
           {/* <HeroSection/> */}
           <HeroSectionNew />
           <BelivingCompanies />
           <NewAccordian/>
           {/* <ClassServices /> */}
           {/* <BussinessService /> */}
           <NewService/>
           {/* <Services/>  */}
          
           {/* <MetaverseSection /> */}
          
           <Clients />
           {/* <Coustumerstories /> */}
           {/* <MapLocation /> */}
           <ConnectMessage /> 

        </div>
    );
};

export default Home;