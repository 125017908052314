import React, { useState } from "react";
import Slider from "react-slick";
import {useNavigate} from 'react-router-dom'
import "./NewAccordian.css";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import front from "../../Component/Images/frontend-img.png";
import back from "../../Component/Images/backend-img.png";
import UI from "../../Component/Images/UI-img.png";
import product from "../../Component/Images/product-img.png";
import arrowRight from "../../Component/Images/arrow-right1.svg";

export default function NewAccordian() {
    const navigate = useNavigate()
  // const [display, setDisplay] = useState(true);
  // const [width, setWidth] = useState(600);
  const [currentSlide, setCurrentSlide] = useState(0);
  // const [totalSlides, setTotalSlides] = useState(0);
  const settings = {
   
    infinite: false,
    speed: 500,
    slidesToShow: 3,
    slidesToScroll: 1,
    beforeChange: (current, next) => setCurrentSlide(next),
    // afterChange: (current) => setTotalSlides(current),
    prevArrow: <CustomPrevArrow isDisabled={currentSlide === 0} />,
    nextArrow: <CustomNextArrow isDisabled={currentSlide === 1} />,
    responsive: [
      {
        breakpoint: 1200,
        settings: {
          slidesToShow: 3,
          slidesToScroll: 1,
        },
      },
      {
        breakpoint: 992,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 1,
          
          nextArrow: <CustomNextArrow isDisabled={currentSlide === 2} />,
         
        },
      },
      {
        breakpoint: 768,
        arrows: false,
        dots: true, 
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
          nextArrow: <CustomNextArrow isDisabled={currentSlide === 3} />,
        },
      },
    ],
  };

  function CustomPrevArrow(props) {
    const { className, style, onClick, isDisabled } = props;
    return (
      <button
        className={`${className} custom-prev-arrow`}
        style={{
          ...style,
          display: "block",
          borderRadius: "40px",
          padding: "25px 50px",
          border: "1px solid #E4E4E4",
          zIndex: 1,
          position: "absolute",
          
          left: "5rem",
          cursor: isDisabled ? "not-allowed" : "pointer",
          opacity: isDisabled ? 0.5 : 1,
        }}
        onClick={isDisabled ? null : onClick}
        disabled={isDisabled}
      >
        <img
          src={arrowRight}
          alt="error"
          style={{
            width: "50px",
            height: "50px",
            position: "absolute",
            top: "0px",
            right: "50%",
            transform: "rotate(180deg) translate(-50%)"
          }}
        />
      </button>
    );
  }

  function CustomNextArrow(props) {
    const { className, style, onClick, isDisabled } = props;
    return (
      <button
        className={`${className} custom-next-arrow`}
        style={{
          ...style,
          display: "block",
          borderRadius: "40px",
          border: "1px solid #E4E4E4",
          padding: "25px 50px",
          position: "absolute",
         
          left: "15rem",
          cursor: isDisabled ? "not-allowed" : "pointer",
          opacity: isDisabled ? 0.5 : 1,
        }}
        onClick={isDisabled ? null : onClick}
        disabled={isDisabled}
      >
        <img
          src={arrowRight}
          alt="error"
          style={{
            width: "50px",
            height: "50px",
            position: "absolute",
            top: "0px",
            right: "50%",
            transform: "translate(50%)"
          }}
        />
      </button>
    );
  }

  return (
    <div className="comp1">
      {/* <img src='' alt="error" className="img"/> */}
      <h1>We believe the right design will bring your business to life</h1>
      <div className="carousels">
        <Slider {...settings}>
          <div className="card card1" style={cardStyle}>
            <img
              src={product}
              alt="Product & Business Strategy"
              className="imgg"
            />
            <h2>Product and business strategy</h2>
            <p>
              Focuses on specific products, and helps to outline the overall
              direction of the company.
            </p>
            <a className="link" onClick={()=>navigate('/contact_us')}>
              <span className="button_label">Know More</span>
              <img src={arrowRight} alt="error" />
            </a>
          </div>
          <div className="card card2">
            <img src={UI} alt="error" className="imgg" />
            <h2>UX and UI design process</h2>
            <p>
              Understanding user needs, creating intuitive interfaces, and
              iteratively testing for optimal user experience.{" "}
            </p>
            <a className="link" onClick={()=>navigate('/contact_us')}>
              <span class="button_label">Know More</span>
              <img src={arrowRight} alt="error" />
            </a>
          </div>
          <div className="card card3">
            <img src={front} alt="error" className="imgg" />
            <h2>Frontend development</h2>
            <p>
              {" "}
              Transforms ideas into reality! Frontend development combines design and code to build engaging, responsive, and user-friendly web interfaces.{" "}
            </p>
            <a className="link" onClick={()=>navigate('/contact_us')}>
              <span class="button_label">Know More</span>
              <img src={arrowRight} alt="error" />{" "}
            </a>
          </div>
          <div className="card card4">
            <img src={back} alt="error" className="imgg" />
            <h2>Backend development</h2>
            <p>
              Builds the server-side logic of applications. It involves creating
              APIs, databases, and server-side scripts to ensure seamless user
              experiences
            </p>
            <a className="link" onClick={()=>navigate('/contact_us')}>
              <span class="button_label">Know More</span>
              <img src={arrowRight} alt="error" />{" "}
            </a>
          </div>
        </Slider>
      </div>
    </div>
  );
}
const cardStyle = {
  width: "80%",
  backgroundColor: "#f4f4f4",
  borderRadius: "16px",
  padding: "20px",
  boxShadow: "0 4px 8px rgba(0, 0, 0, 0.1)",
  textAlign: "center",
  margin: "0 auto",
};
