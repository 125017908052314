import React, { useState } from "react";
import styled from "styled-components";
import { Container } from "../../Component/styledComponents/styledComponents";
import { useSearchParams } from 'react-router-dom';
import { ApplyJob} from "../../FormHandler";
import CircularLoader from "../../Component/CircularLoader";
import { ToastContainer } from "react-toastify";
<style>
  @import
  url('https://fonts.googleapis.com/css2?family=Josefin+Sans:ital,wght@0,100..700;1,100..700&family=Jost:ital,wght@0,100..900;1,100..900&family=Poppins:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap');
</style>;
const Root = styled.div`
  display: flex;
  background:#eef6ff;
  padding: 100px 0 80px 0;
  @media (max-width: 767px) {
    width: 90%;
    margin:0 auto;
    padding: 60px 0 30px 0;
  }
`;

const DetailContainer = styled.div`
  // margin: 3.5rem 0 0;
  padding-bottom: 1rem;
  background:#fff;
  border-radius: 10px;
`;

const DetailBox = styled.div`
  width: 100%;
 padding:20px 30px;
  margin-right: auto;
  margin-left: auto;

  @media (min-width: 1200px) {
    max-width: 1140px;
  }
  @media (min-width: 992px) {
    max-width: 960px;
    padding:20px 20px;
  }
  // @media (min-width: 768px){
  //   max-width: 720px
  // }
  // @media (min-width: 576px){
  //   max-width: 540px
  padding:20px 20px;
  // }
`;

const CareerDetails = styled.div`
  box-sizing: border-box;
  font-family: Instrument Sans;
  padding: 1vh;
  margin-inline: auto;
  display: flex;
  flex-direction: column;
  flex-wrap: wrap;
`;
const ResumeLine = styled.div`
  color: #00008b;
  margin-bottom: 15px;

  label {
    font-size: 16px;
    margin-bottom: 5px;
    font-weight: 700;
  }
 
`;

const CvAttach = styled.div`
  margin-bottom: 15px;
  display: flex;
  flex-direction: column;

  input[type="file"] {
    height: auto;
    padding: 5px 10px;
    font-size: 12px;
    line-height: 2.5;
    border-radius: 3px;
    color: #555;
    background-color: #fff;
    background-image: none;
    border: 1px solid #ccc;
  }

  label {
    display: inline-block;
    font-size: 12px;
    margin-bottom: 5px;
    font-weight: 700;

    span {
      font-size: 10.8px;
      font-weight: 400;
    }
  }
`;
const PageHeadings = styled.div`
margin-top:20px;
  color: darkblue;
  margin-bottom: 10px;
  width: 100%;

  label {
    display: inline-block;
    max-width: 100%;
    margin-bottom: 0px;
    font-weight: 700;
    font-size: 16px;
  }
`;

const FormDiv = styled.div`
display: grid;
grid-template-columns: repeat(2,1fr);
gap: 20px;
margin-bottom:15px;

div{
  display:flex;
  flex-direction: column;
  gap: 3px;

  input{
    height: 40px;
    padding: 5px 10px;
    font-size: 12px;
    line-height: 1.5;
    border-radius: 3px;
    color: #555;
    background-color: #fff;
    background-image: none;
    border: 1px solid #ccc;
  }
  label{
    span{
      color:red;
    }
  }
}

`;

const FormLabels = styled.div`
  display: inline-block;
  max-width: 100%;
  margin-bottom: 5px;
  font-weight: 700;
  font-size: 12px;
`;

const Textarea = styled.div`
  textarea {
    padding: 5px 10px;
    font-size: 12px;
    line-height: 1.5;
    border-radius: 3px;
    height: auto;
    display: block;
    width: 100%;
    color: #555;
    background-color: #fff;
    background-image: none;
    border: 1px solid #ccc;
  }
`;

const SubmitBtn = styled.div`
margin-top: 2vh;
margin-bottom: 15px;

button{
    background: #fab400 ;
    border: 2px solid #fab400 ;
    border-radius: 8px ;
    color: #111 ;
    font-family: Instrument Sans;
    font-size: 14px ;
    font-weight: 600 ;
    line-height: 21px ;
    padding: 8px 28px ;
    text-align: center ;
    text-transform: uppercase ;
    transition: all .3s ;
    width: 33%;
    cursor:pointer;
}
}
`
const Application = () => {
  const [searchParams] = useSearchParams();
  const id  = searchParams.get('id');
  



  const initialValues = {
    job_id: id,
    name: '',
    email: '',
    message: '',
    file: null,
    consent: false, 
  };

const formik = ApplyJob(initialValues );

  return (
    <Root>
    <Container style={{ justifyContent: 'center' }}>
      <DetailContainer>
        <DetailBox>
          <form onSubmit={formik.handleSubmit}>
            <CareerDetails>
              <ResumeLine>
              <label>RESUME </label>
            
                {/* <span>(Attach the CV to auto-populate the form!)</span> */}
              </ResumeLine>
              <CvAttach>
                <label>
                  Attach CV
                  <span style={{ color: 'red' }}> * </span>
                  <span>(doc / docx / pdf only) </span> <span style={{color:"red"}}>[Max. file size: 5MB]</span>
                </label>
                <input
                
                  type="file"
                  name="file"
                  onChange={(e) => formik.setFieldValue('file', e.currentTarget.files[0])}
                />
                {formik.touched.file && formik.errors.file && (
                  <div style={{ color: 'red' }}>{formik.errors.file}</div>
                )}
              </CvAttach>
              <PageHeadings>
                <label>CANDIDATE'S PERSONAL DETAILS</label>
              </PageHeadings>
              <FormDiv>
                <div>
                  <FormLabels>
                    <label>Name <span> *</span></label>
                  </FormLabels>
                  <input
                    type="text"
                    name="name"
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                    value={formik.values.name}
                  />
                  {formik.touched.name && formik.errors.name && (
                    <div style={{ color: 'red' }}>{formik.errors.name}</div>
                  )}
                </div>
                <div>
                  <FormLabels>
                    <label>Email <span> *</span></label>
                  </FormLabels>
                  <input
                    type="email"
                    name="email"
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                    value={formik.values.email}
                  />
                  {formik.touched.email && formik.errors.email && (
                    <div style={{ color: 'red' }}>{formik.errors.email}</div>
                  )}
                </div>
              </FormDiv>

              <PageHeadings>
                <label>MESSAGE FROM APPLICANT</label>
              </PageHeadings>

              <div style={{ marginBottom: '30px' }}>
                <FormLabels>
                  <label>Cover Notes</label>
                </FormLabels>
                <Textarea>
                  <textarea
                  rows="4"
                    name="message"
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                    value={formik.values.message}
                  />
                  {formik.touched.message && formik.errors.message && (
                    <div style={{ color: 'red' }}>{formik.errors.message}</div>
                  )}
                </Textarea>
              </div>

              <div>
                <FormLabels>
                  <label>Consent</label>
                </FormLabels>
                <div
                  style={{
                    fontStyle: 'italic',
                    lineHeight: '1.4',
                    fontSize: '12px',
                    color: '#111',
                    fontWeight: '400',
                  }}
                >
                  <p style={{ margin: '0px' }}>
                    We process this data for recruitment purposes (e.g.
                    screening, evaluations, employment letter generations,
                    background verifications) only. <br /> We also store this
                    data in our applicant tracking system and are fully
                    compliant with the local laws.
                  </p>
                  <p style={{ margin: '0px 0 20px 0' }}>
                    If you have any questions or concerns, please do not hesitate to contact the recruiter or our reception.
                  </p>
                </div>
                <div
                  style={{
                    marginBottom: '15px',
                    fontWeight: 400,
                    fontSize: '12px',
                    display: 'flex',
                    alignItems: 'center',
                    gap: '6px',
                  }}
                >
                  <input
                    type="checkbox"
                    name="consent"
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                    checked={formik.values.consent}
                  />{' '}
                  I Agree
                  {formik.touched.consent && formik.errors.consent && (
                    <div style={{ color: 'red' }}>{formik.errors.consent}</div>
                  )}
                </div>
              </div>
               <SubmitBtn>
                <button type="submit">   {formik.isSubmitting ? <CircularLoader size={25} /> : "SUBMIT"}</button>
              </SubmitBtn>
            </CareerDetails>
          </form>
        </DetailBox>
      </DetailContainer>
          </Container>
  </Root>
  );
};

export default Application;
