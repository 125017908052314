import React from 'react';
import styled from 'styled-components';
import { useLocation } from 'react-router-dom';

const Container = styled.div`
  margin: 160px 0 110px 0;
  padding: 70px 0;
  text-align: center;
`;

const Lets = styled.p`
  font-size: 30px;
  color: #4d4d4d;
  font-weight: 500;
  margin-bottom:20px;
`;

const Requt = styled.p`
  font-size: 18px;
  color: #00a97c;
  margin: 0;
  padding: 0;
`;

const Soon = styled.p`
  font-size: 16px;
  color: #b4b4b4;
  margin-top: 10px;
`;
const Thankyou = () => {
  const location = useLocation();
  
 
  const data = location.state;

  return (
    <Container>
      <div>
        <Lets>{data? "Application Submitted!": "Let's build together!"}</Lets>
        <Requt>Thank you for {data ? data.message : "requesting Consultation Call"}</Requt>
        <Soon>Our experts will reach you out soon.</Soon>
      </div>
    </Container>
  );
};

export default Thankyou;

