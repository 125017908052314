import "./Services.css";

export default function Services() {
  return (
    <div className="services">
      <div className="section">
        {/* <div className="sec1 sec">
          <div className="sec1-1 secc">
            <h1>Made To <span>Scale</span></h1>
            <p>
            Leverage our software development expertise. We'll turn your ideas into powerful, scalable solutions
            </p>
            <img src="https://appinventiv.com/wp-content/themes/twentynineteen-child/new-images/ikea-drop-img.webp" alt="error" />
          </div>
          <div className="sec1-2">
            <p>Leading Technology Offerings For</p>
            <h5>Startup</h5>
            <h5>Public Entities</h5>
            <h5>Enterprises</h5>
          </div>
        </div> */}
        <div className="sec2 sec">
          <div className="sec2-1 secc">
            <h4>Ideation And Product Design</h4>
            <p>UI/UX</p>
          </div>
          <div className="sec2-2 secc">
            <h4>Mobile app dev</h4>
            <p>PWA</p>
            <p>Mobile first Design</p>
          </div>
          {/* <div className="sec2-3 secc">
            <h4>DIGITAL TRANSFORMATION</h4>
            <p>Supply chain management</p>
            <p>Legal modernization</p>
          </div>
          <div className="sec2-4 secc bottom">
            <h4>ELECTRIC VEHICLES</h4>
            <p>E-mobility</p>
          </div> */}
          <div className="sec4-4 secc bottom">
            <h4>Artificial Intelligence</h4>
            <p>Generative AI</p>
            <p>Computer Vision</p>
            <p>Machine Learning</p>
          </div>
        </div>
        <div className="sec3 sec">
          <div className="sec3-1 secc">
            <h4>Software Development</h4>
            <p>Website Development</p>
            <p>Game Development</p>
            {/* <p>Blockchain Development</p> */}
            <p>Custom CRM Development</p>
            <p>AR/VR Development</p>
            {/* <p>IoT Development</p>
            <p>Microservices</p> */}
            <p>Product Development</p>
          </div>
          <div className="sec3-2 secc bottom">
            <h4>Cloud Services</h4>
            <p>Cloud Managed Services</p>
            <p>Cloud Consulting</p>
          </div>
         
        </div>
        <div className="sec5 sec">
        
          <div className="sec4-2 secc">
            <h4>IT Consulting</h4>
            <p>Healthcare IT Consulting Services</p>
            <p>IT Outsourcing services</p>
          </div>
          <div className="sec4-1 secc">
            <h4>Blockchain Services</h4>
            <p>NFT Marketplace</p>
            <p>Metaverse Development</p>
            <p>Dapp Development</p>
          </div>
          <div className="sec3-3 secc bottom">
            <h4> Analytics</h4>
            {/* <p>Supply chain management</p> */}
            <p>Business Intelligence</p>
            <p>Data Analytics</p>
          </div>
        </div>
        <div className="sec4 sec">
        
          
          <div className="sec4-3 secc bottom">
            <h4>DevOps</h4>
            <p>Continuous Integration (CI)</p>
            <p>Continuous Delivery/Deployment (CD)</p>
            <p>Automation</p>
            <p>Configuration Management</p>
            <p>Monitoring & Observability</p>
            <p>Containerization</p>
            <p>Cloud Computing</p>
            <p>Scalability</p>
            <p>Orchestration</p>
            <p>Security & Compliance</p>

          </div>
          
        </div>
      </div>
    </div>
  );
}