import "./About.css";
import { Link } from "react-router-dom";

export default function About() {
  const handleDownload = () => {
    const link = document.createElement("a");
    link.href = "./BastionExpdf.pdf"; // Ensure the path is accurate
    link.download = "BastionExpdf.pdf"; // Specify the file name
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  };

  return (
    <div className="about">
      <div className="top">
        {/* <div className='data first image'>
    <img src="https://appinventiv.com/wp-content/themes/twentynineteen-child/new-images/about-drop-img.webp" alt="error" />
</div> */}
        <div className="data data2" style={{borderBottom:0}}>
          <Link to="/aboutus">
          <h4>About BastionEx</h4>
          <p>
            Shaping the future with technology and innovation as our guiding
            stars
          </p>
          </Link>
          <div className="pdf" onClick={handleDownload}>
            <button>DOWNLOAD PDF</button>
            <img src="/download.png" alt="error" />
          </div>
        </div>

        <div className="data">
          <Link to="/careers">
            {" "}
            <h4>Start Your Career Journey With Us</h4>
          </Link>
          <p>
            Grow your career and contribute to groundbreaking projects with our
            team.
          </p>
        </div>
        <div className="data">
          <h4>Why Choose Us</h4>
          <p>
            Learn about BastionEx software/ product development lifecycle
            process
          </p>
        </div>
        <div className="data">
          <h4>Core team</h4>
          <p>Meet the brains behind our smooth running and powerful machine</p>
        </div>
      </div>
      {/* <div className='bottom'>
<div className='data first'>
<p>Leading Technology Offers For</p>
<h5>Startup</h5>
<h5>Public Entities</h5>
<h5>Enterprises</h5>
    </div>
   
        </div> */}
    </div>
  );
}
