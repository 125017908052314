import React from 'react';
import styled from 'styled-components';
import { Container } from '../../Component/styledComponents/styledComponents';

const Root = styled.div`
padding:80px 0;
@media(max-width:767px){
    padding:30px 0;
}
`;
const Text = styled.div`
font-size:28px;
line-height: 44px;
font-weight:500;
text-align: center;
@media(max-width:767px){
    font-size:20px;
    line-height: 34px;
}
`;
function InfoSection(props) {
    return (
        <Root>
            <Container>
                <Text>BastionEx is software development company helping businesses, enterprises and start-ups with the 
                    innovative and resilient solutions from 7+ years now. We currently enjoy a stellar reputation in the development 
                    industry because of our customer-centric approach that places a high priority on producing best-quality deliverables. </Text>
            </Container>
        </Root>
    );
}

export default InfoSection;